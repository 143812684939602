import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import { useSelector } from 'react-redux'

import LoginPage from '../ui/login'
import ResetPasswordPage from '../ui/forgot'
import UpdatePasswordPage from '../ui/forgot/update'
import RegistrationPage from '../ui/supplier/register'
import RegistrationInvitation from '@supplier/register/invitation'
import EmailVerifyPage from '../ui/supplier/email/verify'

import DashboardRoutes from './router/index'

export default function () {

  const { roles } = useSelector((state) => state.login)
  const dashboard = roles.reduce((cary, item) => (cary || (item.includes('shop') ? 'shop' : 'supplier')), '')

  return (
    <Router>
      <Switch>
        <Route exact path="/registration" component={RegistrationPage}/>
        <Route path="/registration/:hash" component={RegistrationInvitation}/>
        <Route path="/login" component={LoginPage}/>
        <Route path="/forgot-password" component={ResetPasswordPage}/>
        <Route path="/update-password/:hash" component={UpdatePasswordPage}/>
        <Route path="/email/verify/:hash" component={EmailVerifyPage}/>

        <DashboardRoutes dashboard={dashboard}/>
      </Switch>
    </Router>
  )
}
