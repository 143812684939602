import { combineReducers } from 'redux'

import email from './email/reducer'
import products from './product/reducer'
import quotation from './quotations/quotation/reducer'
import quotations from './quotations/reducer'
import order from './orders/order/reducer'
import orders from './orders/reducer'
import register from './register/reducer'
import invitation from './register/invitation/reducer'
import company from './company/reducer'

export default combineReducers({
  email,
  products,
  quotation,
  quotations,
  order,
  orders,
  register,
  invitation,
  company,
})
