import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL_SHOP } from '@config'

export const clearState = createAction('shop/company/clearState')

export const companySetSelectedSupplier = createAction('shop/company/setSelectedSupplier')
export const companyUpdateSetSupplier = createAction('shop/company/update/setSupplier')
export const companyUpdateSetEnabled = createAction('shop/company/update/setEnabled')
export const companyUpdateSetChecked = createAction('shop/company/update/setChecked')

export const fetchCompanyRequest = createAction('shop/company/fetch/before')
export const fetchCompanySuccess = createAction('shop/company/fetch/success')
export const fetchCompanyFailure = createAction('shop/company/fetch/failure')

export function fetchCompany (id) {
  return (dispatch) => {
    dispatch(fetchCompanyRequest())

    return axios.get(`${API_URL_SHOP}/company/${id}`)
      .then(({ data }) => {
        dispatch(fetchCompanySuccess(data))
      })
      .catch(reason => {
        dispatch(fetchCompanyFailure(reason))
      })
  }
}

export const fetchSuppliersRequest = createAction('shop/company/suppliers/before')
export const fetchSuppliersSuccess = createAction('shop/company/suppliers/success')
export const fetchSuppliersFailure = createAction('shop/company/suppliers/failure')

export function fetchSuppliers () {
  return (dispatch) => {
    dispatch(fetchSuppliersRequest())

    return axios.get(`${API_URL_SHOP}/company/suppliers`)
      .then(({ data }) => {
        dispatch(fetchSuppliersSuccess(data))
      })
      .catch(reason => {
        dispatch(fetchSuppliersFailure(reason))
      })
  }
}

export const saveCompanySupplierRequest = createAction('shop/save-company-supplier/request')
export const saveCompanySupplierSuccess = createAction('shop/save-company-supplier/success')
export const saveCompanySupplierFailure = createAction('shop/save-company-supplier/failure')

export function saveCompanySupplier ({ id, supplierId }) {
  return (dispatch) => {
    dispatch(saveCompanySupplierRequest())

    return axios.put(`${API_URL_SHOP}/company/${id}`, { supplier_id: supplierId })
      .then(({ data }) => {
        dispatch(saveCompanySupplierSuccess(data))
      })
      .catch(reason => {
        dispatch(saveCompanySupplierFailure(reason))
      })
  }
}

export const toggleCheckedRequest = createAction('shop/company/checked/request/request')
export const toggleCheckedSuccess = createAction('shop/company/checked/request/success')
export const toggleCheckedFailure = createAction('shop/company/checked/request/failure')

export function toggleChecked ({ companyPublicId }) {
  return (dispatch) => {
    dispatch(toggleCheckedRequest())

    return axios.post(`${API_URL_SHOP}/company/toggle-checked/${companyPublicId}`)
      .then(({ data }) => {
        dispatch(toggleCheckedSuccess(data))
      })
      .catch(reason => {
        dispatch(toggleCheckedFailure(reason.response.data.errors))

        return Promise.reject(reason)
      })
  }
}

export const toggleEnabledRequest = createAction('shop/company/checked/request/request')
export const toggleEnabledSuccess = createAction('shop/company/checked/request/success')
export const toggleEnabledFailure = createAction('shop/company/checked/request/failure')

export function toggleEnabled ({ companyPublicId }) {
  return (dispatch) => {
    dispatch(toggleEnabledRequest())

    return axios.post(`${API_URL_SHOP}/company/toggle-enabled/${companyPublicId}`)
      .then(({ data }) => {
        dispatch(toggleEnabledSuccess(data))
      })
      .catch(reason => {
        dispatch(toggleEnabledFailure(reason.response.data.errors))

        return Promise.reject(reason)
      })
  }
}
