import React from 'react'
import { Label, Segment, Table } from 'semantic-ui-react'

import RemoveUserModal from './remove'
import GenerateInvitationModal from '@components/company/users/invitation'

const CompanyUsers = ({ loading = true, company = {}, users = [], editable = false, remove = () => {}, invitation = () => {} }) => {

  const isAdmin = (user) => {

    if (!user.roles) {return false}

    return !!user.roles.find((role = {}) => (role.role || '').includes('admin'))
  }

  const mappedUsers = [...users].map(u => ({ ...u, isAdmin: isAdmin(u) }))

  return (
    <Segment raised style={{ padding: '0' }}>
      <Table style={{ border: 'none' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={editable ? 1 : 5}>Users ({users.length})</Table.HeaderCell>
            {editable ? (
              <Table.HeaderCell colSpan={2} textAlign="right">
                <GenerateInvitationModal loading={loading} company={company} onSave={invitation}/>
              </Table.HeaderCell>
            ) : false}
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>E-mail</Table.HeaderCell>
            <Table.HeaderCell/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {mappedUsers.map(user =>
            <Table.Row key={user.public_id}>
              <Table.Cell>
                {user.isAdmin ? (<Label color="teal" ribbon>Admin</Label>) : false}
                {user.name}
              </Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              {editable && !user.isAdmin ? (
                <Table.Cell>
                  <RemoveUserModal user={user} onRemove={remove}/>
                </Table.Cell>
              ) : (
                <Table.Cell/>
              )}
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Segment>
  )
}

export default CompanyUsers