import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL_SUPPLIER } from '../../../config/environments'

export const registerRequest = createAction('company-register/request')
export const registerSuccess = createAction('company-register/success')
export const registerFailure = createAction('company-register/failure')

export const setCompanyName = createAction('company-register/setCompanyName')
export const setCompanyDomain = createAction('company-register/setCompanyDomain')

export const setUserName = createAction('company-register/setUserName')
export const setUserEmail = createAction('company-register/setUserEmail')
export const setUserPassword = createAction('company-register/setUserPassword')
export const setUserPasswordConfirm = createAction('company-register/setUserPasswordConfirm')

export function registerCompany ({ company, user }) {
  return (dispatch) => {
    dispatch(registerRequest())

    return axios.post(`${API_URL_SUPPLIER}/company/register`, { company, user })
      .then(({ data }) => {
        dispatch(registerSuccess(data))
      })
      .catch(error => {
        if (error.isAxiosError) {
          const { response: { data: { errors = {} } } = {} } = error
          dispatch(registerFailure(errors))
          return
        }

        dispatch(registerFailure(['Email or password is incorrect']))
      })
  }
}
