import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL_SHOP } from '@config'

export const fetchOrderRequest = createAction('shop/order/fetch/before')
export const fetchOrderSuccess = createAction('shop/order/fetch/success')
export const fetchOrderFailure = createAction('shop/order/fetch/failure')

export function fetchOrder (id) {
  return (dispatch) => {
    dispatch(fetchOrderRequest())

    return axios.get(`${API_URL_SHOP}/order/${id}`)
      .then(({ data }) => {
        dispatch(fetchOrderSuccess(data))
      })
      .catch(reason => {
        dispatch(fetchOrderFailure(reason))
      })
  }
}

export const updateOrderStatusRequest = createAction('shop/order/update-status/before')
export const updateOrderStatusSuccess = createAction('shop/order/update-status/success')
export const updateOrderStatusFailure = createAction('shop/order/update-status/failure')

export function updateOrderStatus ({ id, status }) {
  return (dispatch) => {
    dispatch(updateOrderStatusRequest())

    return axios.put(`${API_URL_SHOP}/order/${id}`, { status })
      .then(() => {
        dispatch(updateOrderStatusSuccess())
      })
      .catch(reason => {
        dispatch(updateOrderStatusFailure(reason))
      })
  }
}

export const setPoStringField = createAction('shop/order/setPoStringField')
export const setPoProductField = createAction('shop/order/setPoProductField')

export const sendPurchaseOrderRequest = createAction('shop/order/send-po/before')
export const sendPurchaseOrderSuccess = createAction('shop/order/send-po/success')
export const sendPurchaseOrderFailure = createAction('shop/order/send-po/failure')

export function sendPurchaseOrder ({ public_id, po }) {
  return (dispatch) => {
    dispatch(sendPurchaseOrderRequest())

    return axios.post(`${API_URL_SHOP}/order/send-purchase-order/${public_id}`, { ...po })
      .then(({ data }) => {
        dispatch(sendPurchaseOrderSuccess(data))
      })
      .catch(({ message = '', response: { data: { errors = {} } = {} } = {} }) => {
        dispatch(sendPurchaseOrderFailure(errors))

        return Promise.reject(message)
      })
  }
}
