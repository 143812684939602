import React from 'react'
import { Label } from 'semantic-ui-react'
import { Form, Input } from 'semantic-ui-react-form-validator'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { auth, authSetEmail, authSetPassword } from './actions'
import { Redirect } from 'react-router'

const Errors = ({ errors = [] }) => {
  if (!errors.length) {
    return false
  }

  return (
    <div className="errors" style={{ width: '100%' }}>
      {errors.map((error, index) =>
        <Label color="red" key={index} style={{
          color: 'white',
          width: '260px',
          marginTop: '15px',
          fontSize: '16px',
          borderRadius: '0'
        }}>{error}</Label>
      )}
    </div>
  )
}

export const LoginForm = () => {
  const dispatch = useDispatch()

  const { form: { email, password }, errors } = useSelector((state) => state.login)

  const login = () => {
    dispatch(auth({ email, password }))
  }

  return (
    <Form size="large" onSubmit={login}>
      <Input
        onChange={({ target: { value } }) => dispatch(authSetEmail(value))}
        value={email}
        type="email"
        fluid
        icon="user"
        iconPosition="left" placeholder="E-mail address"
        required
        width="8"
        style={{ borderRadius: '0', height: '50px' }}
      />
      <Input
        onChange={({ target: { value } }) => dispatch(authSetPassword(value))}
        value={password}
        fluid
        icon="lock"
        iconPosition="left"
        placeholder="Password"
        type="password"
        required
        width="8"
        style={{ borderRadius: '0', height: '50px' }}
      />
      <div className={'link__container'}>
        <Link to={'/forgot-password'}>Forgot your password ?</Link>
      </div>
      <Errors errors={errors}/>
      <button className="button-primary">Login</button>
    </Form>
  )
}

const LoginPage = () => {

  const { token } = useSelector((state) => state.login)

  if (token !== '') {
    return <Redirect to="/"/>
  }

  return (
    <main>
      <div className="authentication-page__wrapper">
        <div className="background__wrapper background__wrapper_login-page">
          <div className="background-pattern background-pattern_login-page"/>
          <div className="text__container">
            <p className="title">Alone, we can do so little; together, we can do so much</p>
            <p>Helen Keller</p>
            <ul>
              <li>Created and designed by industry’s experts</li>
              <li>Connecting the industry</li>
              <li>Secure payment</li>
              <li>24/7 One stop shop</li>
            </ul>
          </div>
        </div>
        <div className="form__wrapper" style={{ background: 'white' }}>
          <div className="logo__container">
            <div className="logo-img"/>
          </div>
          <div className="form__container">
            <h2>Login to Run in All Supplier Module</h2>
            <LoginForm/>
            <div className="link__container">
              <p>Don't have an account?</p>
              <Link to={'/registration'}>Register here</Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default LoginPage
