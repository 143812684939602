import {
  fetchOrderFailure,
  fetchOrderRequest,
  fetchOrderSuccess,
  sendPurchaseOrderFailure,
  sendPurchaseOrderRequest,
  sendPurchaseOrderSuccess,
  setPoProductField,
  setPoStringField,
  updateOrderStatusFailure,
  updateOrderStatusRequest,
  updateOrderStatusSuccess,
} from './actions'

import { formatProducts } from '@util'

const initialState = {
  loading: false,
  company: {},
  history: [],
  order: {},
  quotation: {},
  ui: {
    status: {
      loading: false,
    },
  },
  purchaseOrder: {
    products: [],
    edited_by: 'Florent Vitale',
    job: 'CEO of Run In All, LLC',
    payment_terms: '15 days from the date of invoice',
    contact_details: 'florentv@runinall.com\n+33648391112',
    incoterm: '',
    subtotal: '',
    sales_tax: '',
    total: '',
    comment: '',
  },
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchOrderRequest.toString():
      return { ...state, loading: true }
    case fetchOrderSuccess.toString(): {
      const { quotation } = payload
      const products = formatProducts(quotation)

      const purchaseOrderProducts = products.map(p => {
        let description = [
          p.name,
          ...p.attributes.map(attr => `${attr.group_public}: ${attr.name}`)
        ].join('\n')

        return {
          key: p.key,
          product_id: p.id,
          combination_id: p.combination_id,
          quantity: p.qty,
          description,
          incoterm: '',
          unit_price: '',
          line_total: '',
        }
      })

      return {
        ...state,
        ...payload,
        loading: false,
        quotation: { ...quotation, products },
        purchaseOrder: {
          ...state.purchaseOrder,
          incoterm: `${quotation.incoterm}; ${quotation.incoterm_transport}`,
          products: purchaseOrderProducts,
        },
      }
    }
    case fetchOrderFailure.toString():
      return { ...state, loading: false }

    // status
    case updateOrderStatusRequest.toString():
      return { ...state, loading: true, }
    case updateOrderStatusSuccess.toString():
      return { ...state, loading: false, }
    case updateOrderStatusFailure.toString():
      return { ...state, loading: false, }

    // purchase order
    case setPoStringField.toString(): {
      const { name, value } = payload

      return {
        ...state,
        purchaseOrder: { ...state.purchaseOrder, [name]: value },
      }
    }

    case setPoProductField.toString(): {
      const { index, name, value } = payload

      const products = state.purchaseOrder.products.map((p, i) => {
        if (i === index) {
          return { ...p, [name]: value }
        }

        return p
      })

      return {
        ...state,
        purchaseOrder: { ...state.purchaseOrder, products },
      }
    }

    case sendPurchaseOrderRequest.toString():
      return { ...state, loading: true, }
    case sendPurchaseOrderFailure.toString():
      return { ...state, loading: false, errors: payload }
    case sendPurchaseOrderSuccess.toString():
      return { ...state, loading: false, }

  }

  return state
}
