import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL } from '@config'

export const setPassword = createAction('update-password/setPassword')
export const setPasswordRepeat = createAction('update-password/setPasswordRepeat')

export const getResetDataRequest = createAction('update-password/check/before')
export const getResetDataSuccess = createAction('update-password/check/success')
export const getResetDataFailure = createAction('update-password/check/failure')

export function getResetData ({ hash }) {
  return (dispatch) => {
    dispatch(getResetDataRequest())

    return axios.get(`${API_URL}/reset-password/${hash}`)
      .then(({ data }) => {
        dispatch(getResetDataSuccess(data))
      })
      .catch(reason => {
        dispatch(getResetDataFailure(reason))
      })
  }
}

export const updatePasswordRequest = createAction('update-password/before')
export const updatePasswordSuccess = createAction('update-password/success')
export const updatePasswordFailure = createAction('update-password/failure')

export function updatePassword ({ hash, password, passwordRepeat }) {

  return (dispatch) => {
    dispatch(updatePasswordRequest())

    return axios.post(`${API_URL}/reset-password/${hash}`, {password, password_confirmation: passwordRepeat})
      .then(({ data }) => {
        dispatch(updatePasswordSuccess(data.success))
      })
      .catch(({ response : {status, data : {errors = {}, messages = []}} = {} }) => {
        if (status === 422) {
          if (errors.password) {
            dispatch(updatePasswordFailure(errors.password))
          } else if (messages.length) {
            dispatch(updatePasswordFailure(messages))
          }
        }
      })
  }
}
