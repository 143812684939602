import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Segment, } from 'semantic-ui-react'

import PurchaseOrderModal from './purchase-order'
import { updateOrderStatus } from './actions'

export const ChangeStatus = ({ order, onSubmit = () => {} }) => {
  const dispatch = useDispatch()

  const { public_id, status_id } = order
  const { loading } = useSelector((state) => state.shop.order)

  const updateStatus = ({ status }) => {
    dispatch(updateOrderStatus({ id: public_id, status })).then(() => {
      onSubmit()
    })
  }

  if (status_id === 1) {
    return (<Segment><PurchaseOrderModal onSubmit={onSubmit}/></Segment>)
  }

  if (status_id === 5) {
    return (
      <Segment>
        <Button
          loading={loading}
          size="small"
          style={{ color: 'white', backgroundColor: '#57979A' }}
          onClick={() => updateStatus({ status: 6 })}
        >Mark as Delivered</Button>
      </Segment>
    )
  }

  return false
}

export default ChangeStatus