import { verifyFailure, verifyRequest, verifySuccess } from './actions'

const initialState = {
  loading: true,
  verified: false,
  error: false,
}

export default (state = initialState, { type }) => {

  switch (type) {
    case verifyRequest.toString():
      return { ...state, loading: true }
    case verifySuccess.toString():
      return { ...initialState, verified: true }
    case verifyFailure.toString():
      return { ...state, loading: false, error: true }
  }

  return state
}
