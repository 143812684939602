import React, { Fragment, useEffect } from 'react'
import { Segment } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import { BackButton } from '@components'
import { CompanyConfigs, CompanyHeader, CompanyUsers } from '@components/company'

import {
  changeConfig,
  createInvitation,
  deleteUser,
  fetchCompany,
  rollbackConfigs,
  saveCompanyConfigs,
  toggleEditMode
} from './actions'

const Company = () => {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCompany())
  }, [true])

  const {
    loading,
    company,
    company: { users = [], configs = [] },
    put,
    editMode
  } = useSelector((state) => state.supplier.company)

  const cancel = () => {
    dispatch(rollbackConfigs())
    dispatch(toggleEditMode(false))
  }

  const save = () => {
    dispatch(saveCompanyConfigs({ ...put })).then(() => {
      dispatch(toggleEditMode(false))
    }).catch(() => {})
  }

  const change = (type, value) => {
    dispatch(changeConfig({ type, value }))
  }

  const removeUser = ({ user }) => {
    return dispatch(deleteUser(user)).then(() => {
      dispatch(fetchCompany())
    }).catch(() => {})
  }

  return (
    <Fragment>
      <BackButton/>

      <Segment.Group raised style={{ marginTop: '3em', width: '60em' }}>
        <CompanyHeader
          loading={loading}
          editable={false}
          company={company}
        />

        <Segment>
          <CompanyConfigs
            company={company}
            configs={configs}
            put={put}
            editable={true}
            editMode={editMode}
            toggle={() => dispatch(toggleEditMode(true))}
            change={change}
            save={save}
            cancel={cancel}
          />
        </Segment>
      </Segment.Group>

      <CompanyUsers
        loading={loading}
        company={company}
        users={users}
        editable={true}
        remove={removeUser}
        invitation={({ email = null, domain = false }) => dispatch(createInvitation({ email, domain }))}
      />
    </Fragment>
  )
}

export default Company
