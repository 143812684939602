import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL_SUPPLIER } from '@config'

export const fetchOrderRequest = createAction('supplier/order/fetch/before')
export const fetchOrderSuccess = createAction('supplier/order/fetch/success')
export const fetchOrderFailure = createAction('supplier/order/fetch/failure')

export function fetchOrder (id) {
  return (dispatch) => {
    dispatch(fetchOrderRequest())

    return axios.get(`${API_URL_SUPPLIER}/order/${id}`)
      .then(({ data }) => {
        dispatch(fetchOrderSuccess(data))
      })
      .catch(reason => {
        dispatch(fetchOrderFailure(reason))
      })
  }
}

export const updateOrderStatusRequest = createAction('supplier/order/update-status/before')
export const updateOrderStatusSuccess = createAction('supplier/order/update-status/success')
export const updateOrderStatusFailure = createAction('supplier/order/update-status/failure')

export function updateOrderStatus ({ id, status }) {
  return (dispatch) => {
    dispatch(updateOrderStatusRequest())

    return axios.put(`${API_URL_SUPPLIER}/order/${id}`, { status })
      .then(({ data }) => {
        dispatch(updateOrderStatusSuccess(data))
      })
      .catch(reason => {
        dispatch(updateOrderStatusFailure(reason))
      })
  }
}
