import { clearState, fetchOrdersFailure, fetchOrdersRequest, fetchOrdersSuccess } from './actions'

const initialState = {
  loading: false,
  list: [],
  count: 0,
  limit: 10
}

export default (state = initialState, { type, payload }) => {

  switch (type) {
    case clearState.toString():
      return { ...initialState }
    case fetchOrdersRequest.toString():
      return { ...state, loading: true }
    case fetchOrdersSuccess.toString():
      return { ...state, loading: false, ...payload }
    case fetchOrdersFailure.toString():
      return { ...state, loading: false }
  }

  return state
}
