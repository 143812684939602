import React, { useEffect } from 'react'
import { Container, Segment, } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { cleanState, fetchQuotation } from './actions'

import { BackButton, Products } from '@components'
import { QuotationHeader } from '@components/quotation'
import AnswerFormModal from './components/form'

const Quotation = () => {
  const { reference } = useParams()

  const dispatch = useDispatch()

  const load = () => {
    dispatch(fetchQuotation({ reference }))
  }

  useEffect(() => {
    load()
    return () => {
      dispatch(cleanState())
    }
  }, [reference])

  const {
    quotation,
    quotation: { status: {id: status} },
    products,
    loading,
  } = useSelector((state) => state.supplier.quotation)

  return (
    <Container>

      <BackButton/>

      <Segment.Group raised style={{ marginTop: '3em' }}>
        <QuotationHeader quotation={quotation} loading={loading}/>
        <Segment style={{ paddingTop: '5em' }}>
          <Products
            loading={loading}
            products={products}
            button={
              status === 1 ? (
                <AnswerFormModal quotation={quotation} onSave={load}/>
              ) : false
            }
          />
        </Segment>
      </Segment.Group>

    </Container>
  )
}

export default Quotation
