import React, { useEffect } from 'react'
import { Image, Label } from 'semantic-ui-react'
import { Form, Input } from 'semantic-ui-react-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import { updatePassword, setPassword, setPasswordRepeat, getResetData } from './actions'
import logoBlack from '@static/logoBlack.svg'
import { Link, useParams } from 'react-router-dom'

const Errors = ({ errors = [] }) => {
  if (!errors.length) {
    return false
  }

  return (
    <div className="errors" style={{ width: '100%' }}>
      {errors.map((error, index) =>
        <Label color="red" key={index} style={{
          color: 'white',
          width: '100%',
          marginTop: '15px',
          fontSize: '16px',
          borderRadius: '0',
        }}>{error}</Label>
      )}
    </div>
  )
}

export const UpdatePasswordForm = () => {
  const dispatch = useDispatch()

  const { hash } = useParams()

  const { password, passwordRepeat, success, resetInfo: { email, available }, errors } = useSelector((state) => state.update)

  const goUpdate = () => {
    dispatch(updatePassword({ hash, password, passwordRepeat }))
  }

  if (success) {
    return (
      <div className={'form__container'} style={{ marginLeft: '0' }}>
        <h3>Congratulations ! Password changed</h3>
        <div className={'link__container'}>
          <Link to="/login">Back to login</Link>
        </div>
      </div>
    )
  } else if (available) {
    return (
      <div className={'form__container'} style={{ marginLeft: '0' }}>
        <h2 style={{ paddingTop: '15px', fontSize: '36px' }}>Set New Password</h2>
        <Form size="large" onSubmit={goUpdate}>
          <Input
            onChange={({ target: { value } }) => dispatch(setPassword(value))}
            value={password}
            type="password"
            fluid
            iconPosition="left" placeholder="New password"
            required
            width="8"
            style={{ borderRadius: '0', height: '50px' }}
          />
          <Input
            onChange={({ target: { value } }) => dispatch(setPasswordRepeat(value))}
            value={passwordRepeat}
            type="password"almost done
            fluid
            iconPosition="left" placeholder="Repeat password"
            required
            width="8"
            style={{ borderRadius: '0', height: '50px' }}
          />
          <p className={'info-text'}>Change password for <span style={{color: '#F74623'}}>{email}</span></p>
          <Errors errors={errors}/>
          <button className="button-primary">Continue</button>
        </Form>
      </div>
    )
  } else {
    return (
      <div className={'form__container'} style={{ marginLeft: '0' }}>
        <h2 style={{ paddingTop: '15px', fontSize: '36px' }}>Unavailable link</h2>
      </div>
    )
  }
}

const UpdatePasswordPage = () => {
  const { hash } = useParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getResetData({ hash }))
  }, [hash])

  return (
    <main className={'page_password'}>
      <div className={'auth__container'}>
        <div className={'authentication-page__wrapper recover-password-page'}>
          <div className={'form__wrapper'}>
            <div className={'logo__container'} style={{ marginLeft: '0' }}>
              <Image className={'logo-img'} style={{ height: '100px' }} src={logoBlack}/>
            </div>
            <UpdatePasswordForm/>
          </div>
        </div>
      </div>
    </main>
  )
}

export default UpdatePasswordPage
