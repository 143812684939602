import {
  fetchOrderFailure,
  fetchOrderRequest,
  fetchOrderSuccess,
  updateOrderStatusFailure,
  updateOrderStatusRequest,
  updateOrderStatusSuccess,
} from './actions'
import { formatProducts } from '@util'

const initialState = {
  loading: false,
  company: {},
  history: [],
  order: {},
  quotation: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchOrderRequest.toString():
      return { ...state, loading: true }
    case fetchOrderSuccess.toString(): {
      const { quotation } = payload
      const products = formatProducts(quotation)

      return {
        ...state,
        ...payload,
        loading: false,
        quotation: { ...quotation, products },
      }
    }
    case fetchOrderFailure.toString():
      return { ...state, loading: false }

    // status

    case updateOrderStatusRequest.toString():
      return { ...state, loading: true, }
    case updateOrderStatusSuccess.toString():
      return { ...state, loading: false, order: payload, }
    case updateOrderStatusFailure.toString():
      return { ...state, loading: false, }
  }

  return state
}
