import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL_SUPPLIER } from '@config'

export const setUserName = createAction('supplier/register/setUserName')
export const setUserEmail = createAction('supplier/register/setUserEmail')
export const setUserPassword = createAction('supplier/register/setUserPassword')
export const setUserPasswordConfirm = createAction('supplier/register/setUserPasswordConfirm')

export const registerRequest = createAction('supplier/register/before')
export const registerSuccess = createAction('supplier/register/success')
export const registerFailure = createAction('supplier/register/failure')

export function register ({ hash, user }) {
  return (dispatch) => {
    dispatch(registerRequest())

    return axios.post(`${API_URL_SUPPLIER}/user/register`, { invitation: hash, user })
      .then(({ data = [] }) => {
        dispatch(registerSuccess(data))
      })
      .catch(error => {
        if (error.isAxiosError) {
          const { response: { data: { errors = {} } } = {} } = error
          dispatch(registerFailure(errors))
          return
        }

        dispatch(registerFailure(['Email or password is incorrect']))
      })
  }
}

export const hashInfoRequest = createAction('supplier/hash-info/before')
export const hashInfoSuccess = createAction('supplier/hash-info/success')
export const hashInfoFailure = createAction('supplier/hash-info/failure')

export function hashInfo ({ hash }) {
  return (dispatch) => {
    dispatch(hashInfoRequest())

    return axios.get(`${API_URL_SUPPLIER}/invitation/${hash}`)
      .then(({ data }) => {
        dispatch(hashInfoSuccess(data))
      })
      .catch(() => {
        dispatch(hashInfoFailure(
          ['Link not found or expired. Please ask you company administrator to generate a new one']
        ))
      })
  }
}
