import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Input, Message, Modal, Table } from 'semantic-ui-react'

import { sendPurchaseOrder, setPoProductField, setPoStringField } from './actions'

const Errors = ({ errors = [] }) => {
  if (errors.length === 0) {
    return false
  }

  return <Message
    error
    list={errors}
  />
}

const InputField = ({ name, label, placeholder = '', index = null }) => {
  const { purchaseOrder, purchaseOrder: { products = [] }, errors = {} } = useSelector((state) => state.shop.order)

  const dispatch = useDispatch()
  const change = (value) => {
    if (index !== null) {
      dispatch(setPoProductField({ index, name, value }))
    } else {
      dispatch(setPoStringField({ name, value }))
    }
  }

  let props = {}

  if (label) {props.label = label}
  if (placeholder) {props.placeholder = placeholder}

  const value = index === null ? purchaseOrder[name] : (products[index] || {})[name]
  const errorKey = index === null ? name : `products.${index}.${name}`
  const messages = errors[errorKey] || []

  return (
    <Fragment>
      <Form.Input
        fluid
        error={!!messages.length}
        {...props}
        value={value}
        onChange={(e, { value }) => change(value)}
      />
      {index === null ? <Errors errors={messages}/> : false}
    </Fragment>
  )
}

const TextField = ({ name, label, placeholder = '', rows = 1, resize = true, index = null }) => {
  const { purchaseOrder, purchaseOrder: { products = [] }, errors = {} } = useSelector((state) => state.shop.order)

  const dispatch = useDispatch()
  const change = (value) => {
    if (index !== null) {
      dispatch(setPoProductField({ index, name, value }))
    } else {
      dispatch(setPoStringField({ name, value }))
    }
  }

  let props = {}

  if (label) {props.label = label}
  if (placeholder) {props.placeholder = placeholder}
  if (!resize) {props.style = { resize: 'none' }}

  const value = index === null ? purchaseOrder[name] : (products[index] || {})[name]
  const errorKey = index === null ? name : `products.${index}.${name}`
  const messages = errors[errorKey] || []

  return (
    <Fragment>
      <Form.TextArea
        rows={rows}
        error={!!messages.length}
        {...props}
        value={value}
        onChange={(e, { value }) => change(value)}
      />
      {index === null ? <Errors errors={messages}/> : false}
    </Fragment>
  )
}

const PurchaseOrderForm = () => {

  const {
    purchaseOrder,
    purchaseOrder: { products = [] },
    errors = {}
  } = useSelector((state) => state.shop.order)

  const dispatch = useDispatch()
  const change = (name, value) => {
    dispatch(setPoStringField({ name, value }))
  }

  return (
    <Fragment>
      <Form>
        <Form.Group widths="equal">
          <InputField label="Edited by" name="edited_by"/>
          <InputField label="Job" name="job"/>
          <TextField label="RIA Contact details" name="contact_details" placeholder="Contact data" rows={2}
                     resize={false}/>
        </Form.Group>

        <Form.Group widths="equal">
          <InputField label="Payment terms" placeholder="n days from ..." name="payment_terms"/>
          <InputField label="Incoterm" name="incoterm"/>
        </Form.Group>

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ padding: '0.3em 0.5em' }}
                                width={1}
                                textAlign="center"
              >Quantity</Table.HeaderCell>

              <Table.HeaderCell style={{ padding: '0.3em 0.5em' }}
                                width={10}
              >Description</Table.HeaderCell>

              <Table.HeaderCell style={{ padding: '0.3em 0.5em' }}
                                width={2}
                                textAlign="center"
              >Unit Price ($) (tax excluded)</Table.HeaderCell>

              <Table.HeaderCell style={{ padding: '0.3em 0.5em' }}
                                width={2}
                                textAlign="center"
              >Line Total ($) (tax excluded)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {products.map((p, i) => (
              <Table.Row key={p.key}>
                <Table.Cell style={{ padding: '0.5em' }} textAlign="center">{p.quantity}</Table.Cell>
                <Table.Cell style={{ padding: '0.5em' }}>
                  <TextField index={i} name="description" rows={p.description.split(/\r\n|\r|\n/).length || 1}/>
                </Table.Cell>
                <Table.Cell style={{ padding: '0.5em' }}>
                  <InputField index={i} name="unit_price"/>
                </Table.Cell>
                <Table.Cell style={{ padding: '0.5em' }}>
                  <InputField index={i} name="line_total"/>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <TextField
          label="Comment"
          name="comment"
          rows={6}
        />
      </Form>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      }}>
        <Input style={{ width: 'auto', marginTop: '0.5em' }}
               label="Subtotal ($)"
               error={!!(errors['subtotal'] || []).length}
               value={purchaseOrder.subtotal}
               onChange={(e, { value }) => change('subtotal', value)}
        />

        <Input style={{ width: 'auto', marginTop: '0.5em' }}
               label="Sales Tax ($)"
               error={!!(errors['sales_tax'] || []).length}
               value={purchaseOrder.sales_tax}
               onChange={(e, { value }) => change('sales_tax', value)}
        />

        <Input style={{ width: 'auto', marginTop: '0.5em' }}
               label="TOTAL ($)"
               error={!!(errors['total'] || []).length}
               value={purchaseOrder.total}
               onChange={(e, { value }) => change('total', value)}
        />
      </div>
    </Fragment>
  )
}

const PurchaseOrderModal = ({ onSubmit = () => {} }) => {
  const [open, setOpen] = useState(false)

  const { order: { public_id }, purchaseOrder } = useSelector((state) => state.shop.order)

  const dispatch = useDispatch()
  const submit = () => {
    dispatch(sendPurchaseOrder({ public_id, po: purchaseOrder })).then(() => {
      setOpen(false)
      onSubmit()
    })
      .catch(() => { })
  }

  return (
    <Modal
      dimmer="blurring"
      trigger={<Button style={{ color: 'white', backgroundColor: '#32CD32' }}>Send PO</Button>}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>
        Purchase order form
      </Modal.Header>
      <Modal.Content>
        <PurchaseOrderForm/>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cancel"
          negative
          onClick={() => setOpen(false)}
        />
        <Button
          type="submit"
          content="Send"
          onClick={submit}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export default PurchaseOrderModal