import React, { Fragment } from 'react'
import { Route } from 'react-router'
import { Image, Menu } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import logo from '@static/logo.svg'
import { logout } from '@login/actions'
import { MenuItem } from '@components'
import Quotation from '@supplier/quotations/quotation'
import Quotations from '@supplier/quotations'
import Products from '@supplier/product'
import Company from '@supplier/company'
import Orders from '@supplier/orders'
import Order from '@supplier/orders/order'

function Home () {
  return <div/>
}

const SupplierMenuLayout = ({ children }) => {
  const dispatch = useDispatch()

  const { roles } = useSelector((state) => state.login)
  const isAdmin = roles.reduce((cary, item) => (cary || item.includes('admin')), false)

  return (
    <div style={{ display: 'flex' }}>
      <Menu inverted vertical fixed="left"
            style={{ height: '100vh', borderRadius: 'inherit', margin: 0, backgroundColor: '#4d3b6f' }}>
        <Menu.Item header>
          <Image src={logo} style={{ marginRight: '1.5em', width: '100%' }}/>
        </Menu.Item>
        <MenuItem path="/company" hide={isAdmin === false}>Company</MenuItem>
        <MenuItem path="/quotations" paths={['/quotation/:reference']}>Quotations</MenuItem>
        <MenuItem path="/orders" paths={['/order/:reference']}>Orders</MenuItem>
        <MenuItem path="/products">Products</MenuItem>
        <Menu.Item onClick={() => dispatch(logout())}>Logout</Menu.Item>
      </Menu>
      <div id="main-content" style={{ margin : '20px 40px 50px 250px' }}>
        {children}
      </div>
    </div>
  )
}

const SupplierRoute = ({ component: Component, path: path }) => {
  return (
    <Route exact path={path}>
      <SupplierMenuLayout>
        <Component/>
      </SupplierMenuLayout>
    </Route>
  )
}

export default function () {
  return (
    <Fragment>
      <SupplierRoute path="/" component={Home}/>
      <SupplierRoute path="/company" component={Company}/>
      <SupplierRoute path="/quotations/:page?" component={Quotations}/>
      <SupplierRoute path="/quotation/:reference" component={Quotation}/>
      <SupplierRoute path="/orders/:page?" component={Orders}/>
      <SupplierRoute path="/order/:id" component={Order}/>
      <SupplierRoute path="/products/:page?" component={Products}/>
    </Fragment>
  )
}
