import React from 'react'
import { Label, Segment, Table } from 'semantic-ui-react'
import Moment from 'react-moment'

const OrderHistory = ({ loading = true, history = [] }) => {
  return (
    <Segment raised style={{ padding: '0' }}>
      <Table style={{ border: 'none' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={4}>History</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>E-mail</Table.HeaderCell>
            <Table.HeaderCell>Time</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {history.map(h =>
            <Table.Row key={h.id}>
              <Table.Cell>
                {h.user.name}
              </Table.Cell>
              <Table.Cell>{h.user.email}</Table.Cell>
              <Table.Cell>
                <Moment format="YYYY-MM-DD hh:mm A">{h.updated_at}</Moment>
              </Table.Cell>
              <Table.Cell>
                <Label size="small" style={{ color: 'white', backgroundColor: h.status.color }}>{h.status.name}</Label>
              </Table.Cell>

            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Segment>
  )
}

export default OrderHistory