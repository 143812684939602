import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL_SUPPLIER } from '@config'

export const cleanState = createAction('supplier/quotation/cleanState')

export const quotationAnswerClear = createAction('quotation/answer/clear')
export const quotationAnswerSetPrice = createAction('quotation/answer/setPrice')
export const quotationAnswerSetDeliveryTime = createAction('quotation/answer/setDeliveryTime')

export const fetchQuotationRequest = createAction('quotation/fetch/before')
export const fetchQuotationSuccess = createAction('quotation/fetch/success')
export const fetchQuotationFailure = createAction('quotation/fetch/failure')

export function fetchQuotation ({ reference }) {
  return (dispatch) => {
    dispatch(fetchQuotationRequest())

    return axios.get(`${API_URL_SUPPLIER}/quotation/${reference}`)
      .then(({ data }) => {
        dispatch(fetchQuotationSuccess(data))
      })
      .catch(reason => {
        dispatch(fetchQuotationFailure(reason))
      })
  }
}

export const quotationAnswerRequest = createAction('quotation/answer/request/request')
export const quotationAnswerSuccess = createAction('quotation/answer/request/success')
export const quotationAnswerFailure = createAction('quotation/answer/request/failure')

export function quotationAnswer ({ productId, quotationId, price, deliveryTime, combinationId = 0 }) {
  return (dispatch) => {
    dispatch(quotationAnswerRequest())

    return axios.post(
      `${API_URL_SUPPLIER}/quotation/answer`,
      {
        quotation_id: quotationId,
        product_id: productId,
        combination_id: combinationId,
        delivery_time: deliveryTime,
        price
      }
    )
      .then(({ data }) => {
        dispatch(quotationAnswerSuccess(data))
      })
      .catch(reason => {
        dispatch(quotationAnswerFailure(reason.response.data.errors))

        return Promise.reject(reason)
      })
  }
}
