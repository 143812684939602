import {
  registerFailure,
  registerRequest,
  registerSuccess,
  setCompanyDomain,
  setCompanyName,
  setUserEmail,
  setUserName,
  setUserPassword,
  setUserPasswordConfirm,
} from './actions'

const initialState = {
  loading: false,
  company: {
    name: '',
    domain: '',
  },
  user: {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
  },
  messages: [],
  errors: {},
  created: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case registerRequest.toString():
      return { ...state, loading: true, errors: {} }
    case registerSuccess.toString():
      return { ...initialState, loading: false, messages: payload.messages, created: true, errors: {} }
    case registerFailure.toString():
      return { ...state, loading: false, errors: payload }

    case setCompanyName.toString():
      return { ...state, company: { ...state.company, name: payload } }
    case setCompanyDomain.toString():
      return { ...state, company: { ...state.company, domain: payload } }

    case setUserName.toString():
      return { ...state, user: { ...state.user, name: payload } }
    case setUserEmail.toString():
      return { ...state, user: { ...state.user, email: payload } }
    case setUserPassword.toString():
      return { ...state, user: { ...state.user, password: payload } }
    case setUserPasswordConfirm.toString():
      return { ...state, user: { ...state.user, password_confirmation: payload } }
  }

  return state
}
