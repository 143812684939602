import React, { Fragment, useEffect } from 'react'
import { Button, Icon, Segment, Table } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchCompanies } from './actions'
import { Link } from 'react-router-dom'

const State = ({state = false}) => {

  if (state) {
    return (<Icon color="green" name="checkmark"/>)
  }

  return ( <Icon color="red" name="close"/>)
}

const List = ({ companies = [] }) => {

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={6}>Amount ({companies.length})</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Domain</Table.HeaderCell>
          <Table.HeaderCell>Enabled</Table.HeaderCell>
          <Table.HeaderCell>Checked</Table.HeaderCell>
          <Table.HeaderCell>Supplier</Table.HeaderCell>
          <Table.HeaderCell/>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {companies.map(company =>
          <Table.Row key={company.public_id}>
            <Table.Cell>
              <Link to={`/company/${company.public_id}`}>{company.name}</Link>
            </Table.Cell>
            <Table.Cell>
              <a href={`https://${company.domain}`} target="_blank">{company.domain}</a>
            </Table.Cell>
            <Table.Cell><State state={company.enabled}/></Table.Cell>
            <Table.Cell><State state={company.checked}/></Table.Cell>
            <Table.Cell><State state={!!company.supplier_id}/></Table.Cell>
            <Table.Cell>
              <Link to={'/company/' + company.public_id}>
                <Button animated>
                  <Button.Content visible>Details</Button.Content>
                  <Button.Content hidden>
                    <Icon name="arrow right"/>
                  </Button.Content>
                </Button>
              </Link>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

const Companies = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCompanies())
  }, [true])

  const { list = [] } = useSelector((state) => state.shop.companies)

  return (
    <Fragment>
      <Segment raised style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        Filters
      </Segment>

      <List companies={list}/>

    </Fragment>
  )
}

export default Companies
