import React, { useEffect, useState } from 'react'
import { Button, Label, Modal } from 'semantic-ui-react'
import { Form, Input } from 'semantic-ui-react-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import {
  quotationAnswer,
  quotationAnswerClear,
  quotationAnswerSetDeliveryTime,
  quotationAnswerSetPrice
} from '../actions'

const AnswerFormModal = ({ quotation, product, product: { answer = {} } = {}, onSave = () => {} }) => {
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(quotationAnswerClear())
    dispatch(quotationAnswerSetPrice(answer.price))
    dispatch(quotationAnswerSetDeliveryTime(answer.delivery_time))

    return () => {
      dispatch(quotationAnswerClear())
    }

  }, [product.id, quotation.id])

  const { answer: { price, deliveryTime }, modalErrors = [] } = useSelector((state) => state.supplier.quotation)

  const sendQuotationAnswer = () => {

    dispatch(
      quotationAnswer({
        productId: product.id,
        quotationId: quotation.id,
        combinationId: product.combination_id,
        price,
        deliveryTime
      })
    )
      .then(() => {
        setOpen(false)
        onSave()
      })
  }

  const isNewAnswer = !answer.price && !answer.delivery_time

  if (quotation.status.id !== 1) {return false}

  return (
    <Modal
      as={Form}
      onSubmit={sendQuotationAnswer}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      dimmer="blurring"
      trigger={<Button>{isNewAnswer ? 'Make offer' : 'Edit offer'}</Button>}
    >
      <Modal.Header>Please, set <span>Price</span> and <span>Delivery date</span></Modal.Header>
      <Modal.Content image style={{ display: 'flex', flexDirection: 'column', fontWeight: 'bold' }}>
        <div style={{ maxWidth: '300px', marginBottom: '20px' }}>
          <label>Price<span style={{ color: 'red' }}>*</span></label>
          <Input
            labelPosition="right"
            type="number"
            placeholder="Price"
            value={price}
            onChange={({ target: { value } }) => dispatch(quotationAnswerSetPrice(value))}
            validators={['required', 'minNumber:1', 'maxNumber:10000000']}
            errorMessages={['This field is required', 'The number must be greater than zero', 'The number must be less than 10000000', 'The field must contain only numbers']}
          >
            <Label>{quotation.currency.symbol}</Label>
            <input/>
            <Label>.00</Label>
          </Input>
        </div>
        <div style={{ maxWidth: '500px' }}>
          <label>Delivery time<span style={{ color: 'red' }}>*</span></label>
          <Input
            type="text"
            step="0"
            placeholder="Write about when you will be able to deliver the goods to the client"
            value={deliveryTime}
            onChange={({ target: { value } }) => dispatch(quotationAnswerSetDeliveryTime(value))}
            validators={['required', 'matchRegexp:^[\\.a-zA-Z0-9,!?:"\' ]*$']}
            errorMessages={['This field is required', 'The field must contain only letters and numbers']}
            style={{ width: '100%' }}
          />
        </div>
        <div className="modalErrors" style={{ marginTop: '30px' }}>
          {modalErrors.map(error =>
            <Label color="red" style={{ color: 'white' }}>{error}</Label>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cancel"
          onClick={() => setOpen(false)}
          negative
        />
        <Button
          type="submit"
          content="Save"
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export default AnswerFormModal
