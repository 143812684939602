import { createAction } from 'redux-actions'
import axios from 'axios'

import { API_URL_SUPPLIER } from '../../../../config/environments'

export const verifyRequest = createAction('email-verify/request')
export const verifySuccess = createAction('email-verify/success')
export const verifyFailure = createAction('email-verify/failure')

export function emailVerify (hash) {
  return (dispatch) => {
    dispatch(verifyRequest())

    return axios.get(`${API_URL_SUPPLIER}/email/verify/${hash}`)
      .then(({ data }) => {
        dispatch(verifySuccess(data))
      })
      .catch(({ isAxiosError, response }) => {
        if (!isAxiosError) {
          console.warn('Unexpected response status')
          return
        }

        if (response.status === 404) {
          dispatch(verifyFailure())
        }
      })
  }
}
