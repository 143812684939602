import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import { Button, Icon, Label, Segment, Table } from 'semantic-ui-react'
import Moment from 'react-moment'

import { OrderFilter, OrderStatus } from '@components/order'
import Pagination from '@components/pagination'

const emptyCallback = () => {}

const List = ({ orders = [], count, filterCompanies }) => {

  return (
    <Segment raised style={{ padding: '0' }}>
      <Table style={{ border: 'none' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={6}>Amount ({count})</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Reference</Table.HeaderCell>
            <Table.HeaderCell>Last update</Table.HeaderCell>
            {filterCompanies ? (
              <Fragment>
                <Table.HeaderCell>Company name</Table.HeaderCell>
                <Table.HeaderCell>Shop state</Table.HeaderCell>
              </Fragment>
            ) : false}
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {orders.map(order =>
            <Table.Row key={order.public_id}>
              <Table.Cell><Link to={`/order/${order.public_id}`}>{order.reference}</Link></Table.Cell>
              <Table.Cell><Moment format="YYYY-MM-DD hh:mm A">{order.updated_at}</Moment></Table.Cell>
              {filterCompanies ? (
                <Fragment>
                  <Table.Cell><Link to={`/company/${order.company.public_id}`}>{order.company.name}</Link></Table.Cell>
                  <Table.Cell>
                    <Label style={{
                      backgroundColor: order.shop_order.state.color,
                      color: 'white'
                    }}>{order.shop_order.state.name}</Label>
                  </Table.Cell>
                </Fragment>
              ) : false}
              <Table.Cell>
                <OrderStatus status={order.status}/>
              </Table.Cell>
              <Table.Cell style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Link to={`/order/${order.public_id}`}>
                  <Button animated>
                    <Button.Content visible>Details</Button.Content>
                    <Button.Content hidden>
                      <Icon name="arrow right"/>
                    </Button.Content>
                  </Button>
                </Link>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Segment>
  )
}

const OrderList = ({
  loading = false,
  fetch = emptyCallback,
  clear = emptyCallback,
  orders = [],
  count = 0,
  limit = 0,
  statuses = [],
  reference = '',
  company = '',
  filterCompanies = false
}) => {
  const dispatch = useDispatch()
  const { page = 1 } = useParams()
  const history = useHistory()

  let orderLoading = false
  const loadOrders = () => {
    orderLoading = true
    dispatch(fetch({
        page,
        statuses: statuses.filter(v => !!v.checked).map(v => v.id),
        reference,
        company
      }
    ))

    return () => {
      dispatch(clear())
    }
  }

  // base effect
  useEffect(() => {
    return loadOrders()
  }, [page, JSON.stringify(statuses.map(v => !!v.checked)), company])

  useEffect(() => {
    if (orderLoading) {return }

    if (reference.length === 0) {
      loadOrders()
    }

    if (reference.length < 2) { return }

    const timeout = setTimeout(() => {
      loadOrders()
    }, 300)

    return () => {
      clearTimeout(timeout)
    }

  }, [reference])

  return (
    <Fragment>

      <OrderFilter loading={loading} filterCompanies={filterCompanies} onChange={() => {
        if (page > 1) {
          history.push(`/orders`)
        }
      }}/>

      <List loading={loading} orders={orders} count={count} filterCompanies={filterCompanies}/>

      <Pagination
        loading={loading}
        page={page}
        count={count}
        limit={limit}
        onPageChange={({ activePage }) => {
          history.push(`/orders/${activePage}`)
        }}
      />
    </Fragment>
  )
}

export default OrderList
