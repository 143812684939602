import React from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { createOrder } from './actions'

export const Order = ({ quotation, company }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { order = {} } = company

  if (order.public_id) {

    return (
      <Button
        color="teal"
        as={Link}
        to={`/order/${order.public_id}`}
      >Show order</Button>
    )
  }

  if (quotation.status.code !== 'CLSD') {
    return false
  }

  const createOrderClick = (company) => {
    dispatch(
      createOrder({ quotation_id: quotation.id, company, history })
    )
  }

  return (
    <Button primary
            disabled={company.ui.disabled}
            loading={company.ui.loading}
            onClick={() => createOrderClick(company)}
    >Create order</Button>
  )
}