import React from 'react'
import { Label, Loader } from 'semantic-ui-react'

const QuotationStatus = ({ loading = false, size, status: { color, name } }) => {

  if (loading) {
    return (
      <Label size={size}
             style={{ color: 'rgb(216 197 197 / 60%)', position: 'relative' }}
      >
        Quotation status
        <Loader active={true}/>
      </Label>
    )
  }

  return (
    <Label
      size={size}
      style={{ color: 'white', backgroundColor: color }}
    >{name}</Label>
  )
}

export default QuotationStatus