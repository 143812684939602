import {
  getResetDataRequest,
  getResetDataSuccess,
  getResetDataFailure,
  updatePasswordRequest,
  updatePasswordSuccess,
  updatePasswordFailure,
  setPassword,
  setPasswordRepeat,
} from './actions'

const initialState = {
  loading: false,
  password: '',
  passwordRepeat: '',
  resetInfo: {
    email: '',
    available: false
  },
  success: false,
  errors: []
}

export default (state = initialState, { type, payload }) => {

  switch (type) {
    case getResetDataRequest.toString():
      return { ...state, loading: true }
    case getResetDataSuccess.toString():
      return { ...initialState, resetInfo: payload, loading: false }
    case getResetDataFailure.toString():
      return { ...initialState, errors: payload, loading: false }
    case updatePasswordRequest.toString():
      return { ...state, loading: true }
    case updatePasswordSuccess.toString():
      return { ...initialState, success: payload, loading: false }
    case updatePasswordFailure.toString():
      return { ...state, loading: false, errors: payload }
    case setPassword.toString():
      return { ...state, password: payload }
    case setPasswordRepeat.toString():
      return { ...state, passwordRepeat: payload }
  }

  return state
}
