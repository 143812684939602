import { combineReducers } from 'redux'

import quotations from './quotations/reducer'
import quotation from './quotations/quotation/reducer'
import companies from './companies/reducer'
import company from './companies/company/reducer'
import orders from './orders/reducer'
import order from './orders/order/reducer'

export default combineReducers({
  companies,
  company,
  quotations,
  quotation,
  orders,
  order
})
