import { fetchCompaniesFailure, fetchCompaniesRequest, fetchCompaniesSuccess } from './actions'

const initialState = {
  loading: false,
  list: [],
}

export default (state = initialState, { type, payload }) => {

  switch (type) {
    case fetchCompaniesRequest.toString():
      return { ...state, loading: true }
    case fetchCompaniesSuccess.toString():
      return { ...state, loading: false, list: payload.list }
    case fetchCompaniesFailure.toString():
      return { ...state, loading: false }
  }

  return state
}
