import { createAction } from 'redux-actions'
import axios from 'axios'

import { API_URL_SUPPLIER } from '@config'

export const fetchCompanyRequest = createAction('supplier/company/fetch/before')
export const fetchCompanySuccess = createAction('supplier/company/fetch/success')
export const fetchCompanyFailure = createAction('supplier/company/fetch/failure')

export function fetchCompany () {
  return (dispatch) => {
    dispatch(fetchCompanyRequest())

    return axios.get(`${API_URL_SUPPLIER}/company/`)
      .then(({ data }) => {
        dispatch(fetchCompanySuccess(data))
      })
      .catch(reason => {
        dispatch(fetchCompanyFailure(reason))
      })
  }
}

export const toggleEditMode = createAction('supplier/company/toggleEditMode')
export const changeConfig = createAction('supplier/company/changeConfig')
export const rollbackConfigs = createAction('supplier/company/rollbackConfigs')

export const saveCompanyConfigsRequest = createAction('supplier/company/saveCompanyConfigs/before')
export const saveCompanyConfigsSuccess = createAction('supplier/company/saveCompanyConfigs/success')
export const saveCompanyConfigsFailure = createAction('supplier/company/saveCompanyConfigs/failure')

export function saveCompanyConfigs (configs) {
  return (dispatch) => {
    dispatch(saveCompanyConfigsRequest())

    return axios.put(`${API_URL_SUPPLIER}/company/configs/`, configs)
      .then(({ data }) => {
        dispatch(saveCompanyConfigsSuccess(data))
      })
      .catch(reason => {
        dispatch(saveCompanyConfigsFailure(reason))

        return Promise.reject()
      })
  }
}

export const deleteUserRequest = createAction('supplier/company/deleteUser/before')
export const deleteUserSuccess = createAction('supplier/company/deleteUser/success')
export const deleteUserFailure = createAction('supplier/company/deleteUser/failure')

export function deleteUser ({ public_id }) {
  return (dispatch) => {
    dispatch(deleteUserRequest())

    return axios.delete(`${API_URL_SUPPLIER}/user/${public_id}`)
      .then(({ data }) => {
        dispatch(deleteUserSuccess(data))
      })
      .catch(reason => {
        dispatch(deleteUserFailure(reason))

        return Promise.reject()
      })
  }
}

export const createInvitationRequest = createAction('supplier/company/createInvitation/before')
export const createInvitationSuccess = createAction('supplier/company/createInvitation/success')
export const createInvitationFailure = createAction('supplier/company/createInvitation/failure')

export function createInvitation ({ email = null, domain = false }) {
  return (dispatch) => {
    dispatch(createInvitationRequest())

    return axios.post(`${API_URL_SUPPLIER}/invitation/generate`, { email, domain })
      .then(({ data }) => {
        dispatch(createInvitationSuccess(data))

        return data
      })
      .catch((reason) => {
        dispatch(createInvitationFailure())

        return Promise.reject(reason)
      })
  }
}
