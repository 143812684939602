import {
  changeCompany,
  changeReference, fetchCompaniesFailure, fetchCompaniesRequest, fetchCompaniesSuccess,
  fetchStatusFailure,
  fetchStatusRequest,
  fetchStatusSuccess,
  toggleChecked
} from './actions'

const listPreloaded = [
  { 'color': '#FF8C00', 'name': 'Created', 'id': 1 },
  { 'color': '#32CD32', 'name': 'PO Sent', 'id': 2 },
  { 'color': '#4169E1', 'name': 'Acknowledged', 'id': 3 },
  { 'color': '#F3CD43', 'name': 'Manufacturing', 'id': 4 },
  { 'color': '#108510', 'name': 'Ready for collection', 'id': 5 },
  { 'color': '#57979A', 'name': 'Delivered', 'id': 6 },
]

const initialState = {
  loading: false,
  statuses: [...listPreloaded],
  companies: [],
  reference: '',
  company: ''
}

export default (state = initialState, { type, payload }) => {

  switch (type) {
    case fetchStatusRequest.toString():
      return { ...state, loading: true }
    case fetchStatusSuccess.toString():
      return { ...state, loading: false, statuses: payload.map(v => ({ ...v, checked: false })) }
    case fetchStatusFailure.toString():
      return { ...state, loading: false }
    case fetchCompaniesRequest.toString():
      return { ...state, loading: true }
    case fetchCompaniesSuccess.toString():
      return { ...state, loading: false, companies: payload }
    case fetchCompaniesFailure.toString():
      return { ...state, loading: false }
    case toggleChecked.toString() :
      return {
        ...state,
        statuses: [...state.statuses.map(v => {
          if (v.id === payload) {
            return { ...v, checked: !v.checked }
          }

          return v
        })]
      }
    case changeReference.toString() :
      return { ...state, reference: payload }
    case changeCompany.toString() :
      return { ...state, company: payload }
  }

  return state
}
