import { SHOP_URL } from '@config'
import { useLocation } from 'react-router'
import { keyBy } from 'lodash/collection'

const prepareUrlPart = (word) => {
  let preparedWord = word.trim().toLowerCase().replace(/[^a-zA-Z ^\d.-]/g, ' ').replace(/\s+/g, '-')
  return preparedWord.slice(-1) === '-' ? preparedWord.slice(0, -1) : preparedWord
}

export const formatProducts = ({ products = [] }) => {

  const attributesToName = (attributes = []) => attributes
    .map(attr => `${attr.group_public}: ${attr.name}`)
    .join(', ')

  return products
    .map((product) => {
      // It's a product
      if (product.combination_id === 0) {
        return { ...product, key: `${product.id}_${product.combination_id}` }
      }

      // It's a combination
      return {
        ...product,
        // name: `${product.name} (${attributesToName(product.attributes)})`,
        key: `${product.id}_${product.combination_id}`
      }
    })
    .map(product => ({ ...product, url: createProductUrl(product) }))
}

export const productsWithAnswers = ({ quotation: { answers = [] } = {}, products = [] }) => {
  const keyedAnswers = keyBy(
    [...answers],
    (answer) => `${answer.product_id}_${answer.combination_id}`
  )

  return products.map(product => {
    const answer = { ...(keyedAnswers[`${product.id}_${product.combination_id}`] || {}) }

    return { ...product, answer }
  })
}

export const companiesWithProducts = ({ companies = [], products = [] }) => {
  return companies.map(company => {
    return {
      ...company,
      products: products.filter(p => p.id_supplier === company.supplier_id)
    }
  })
}

export const createProductUrl = (product) => {
  let category = product.category_name,
    product_id = product.id,
    product_name = product.name

  category = prepareUrlPart(category)
  product_name = prepareUrlPart(product_name)

  return `${SHOP_URL}/${category}/${product_id}-${product_name}.html`
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
