import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL_SHOP } from '@config'

export const clearState = createAction('shop/quotations/clearState')

export const fetchOrdersRequest = createAction('shop/orders/fetch/before')
export const fetchOrdersSuccess = createAction('shop/orders/fetch/success')
export const fetchOrdersFailure = createAction('shop/orders/fetch/failure')

export function fetchOrders ({ page, statuses, reference, company }) {
  return (dispatch) => {
    dispatch(fetchOrdersRequest())

    const params = [
      `page=${page}`,
      reference && `reference=${reference}`,
      company && `company=${company}`,
    ].filter(v => !!v).concat(
      statuses.map(s => `${encodeURIComponent('status[]')}=${s}`)
    ).join('&')

    axios.get(`${API_URL_SHOP}/order?${params}`)
      .then(({ data }) => {
        dispatch(fetchOrdersSuccess(data))
      })
      .catch(reason => {
        dispatch(fetchOrdersFailure(reason))
      })
  }
}
