import React, { useEffect } from 'react'
import { Container, Header, Table } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProducts } from './actions'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import Pagination from '@components/pagination'

const List = () => {
  const products = useSelector((state) => state.supplier.products.list)

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Supplier Reference</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {products.map(product =>
          <Table.Row key={product.id}>
            <Table.Cell><a href={product.url} target="_blank">{product.name}</a></Table.Cell>
            <Table.Cell>{product.supplier_reference}</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

const Products = () => {
  const dispatch = useDispatch()
  const { page = 1 } = useParams()
  const history = useHistory()

  const { count, perPage } = useSelector((state) => state.supplier.products)

  useEffect(() => {
    dispatch(fetchProducts({ page }))
  }, [page])

  return (
    <Container>
      <Header as="h1">
        Products ({count})
      </Header>

      <List/>

      <Pagination
        count={count}
        perPage={perPage}
        onPageChange={({ activePage }) => {
          history.push(`/products/${activePage}`)
        }}
      />

    </Container>
  )
}

export default Products
