import React, { Fragment } from 'react'
import { Button, Form, Header, Input, Label } from 'semantic-ui-react'
import { isEqual } from 'lodash/lang'

const ConfigLabel = ({ name, value }) => (
  <div style={{ margin: '0 0 1.25em' }}>
    <Header style={{ marginBottom: '0.3em' }} as="h5">{name}:</Header>
    {!!value ? (
      <Label size="large" color="green">{value}</Label>
    ) : (
      <Label size="large" color="red">Value is required</Label>
    )}
  </div>
)

const ConfigInput = ({ width = 7, name, placeholder, value, label, labelPosition, onChange = () => {} }) => {

  let properties = {}

  if (typeof value !== 'undefined') {
    properties.value = value
  }

  if (typeof onChange !== 'undefined') {
    properties.onChange = onChange
  }

  if (typeof label !== 'undefined') {
    properties.label = label
  }

  if (typeof labelPosition !== 'undefined') {
    properties.labelPosition = labelPosition
  }

  return (
    <Form.Field width={width}>
      <label style={{ fontSize: 'inherit', margin: '0' }}>
        <Header style={{ marginBottom: '0.3em' }} as="h5">{name}:</Header>
        <Input
          type="text"
          // size="small"
          fluid
          required
          placeholder={placeholder || name}
          {...properties}
        />
      </label>
    </Form.Field>
  )
}

const List = ({ configs = {} }) => {
  return (
    <Fragment>
      <ConfigLabel name="Purchase order email" value={configs.po_email}/>
      <ConfigLabel name="Purchase order contact name" value={configs.po_contact_name}/>
      <ConfigLabel name="Address" value={configs.address}/>
      <ConfigLabel name="Phone" value={configs.phone}/>
    </Fragment>
  )
}

const Edit = ({
  company = {},
  configs = {},
  saveDisabled = true,
  change = () => {},
  save = () => {},
  cancel = () => {}
}) => {

  return (
    <Fragment>
      <Form size="small" style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between'
      }}>
        <Fragment>
          <ConfigInput
            name="Purchase order email"
            value={configs.po_email || ''}
            onChange={(e, { value }) => change('po_email', value)}
          />

          <ConfigInput
            name="Purchase order contact name"
            value={configs.po_contact_name || ''}
            onChange={(e, { value }) => change('po_contact_name', value)}
          />

          <ConfigInput
            name="Address"
            value={configs.address || ''}
            onChange={(e, { value }) => change('address', value)}
          />

          <ConfigInput
            name="Phone"
            value={configs.phone || ''}
            onChange={(e, { value }) => change('phone', value)}
          />
        </Fragment>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button
            negative
            onClick={() => cancel()}
            style={{ alignSelf: 'flex-end', marginTop: '1.5em' }}>Cancel</Button>
          <Button
            positive
            disabled={saveDisabled}
            type="submit"
            onClick={() => save()}
            style={{ alignSelf: 'flex-end', marginTop: '1.5em' }}>Save</Button>
        </div>
      </Form>
    </Fragment>
  )
}

const CompanyConfigs = ({
  company = {},
  configs = {},
  put = {},
  editable = false,
  editMode = false,
  toggle = () => {},
  change = () => {},
  save = () => {},
  cancel = () => {}
}) => {
  if (editMode) {
    return (
      <Edit company={company}
            configs={put}
            save={save}
            cancel={cancel}
            change={change}
            saveDisabled={isEqual(configs, put)}
      />
    )
  }

  return (
    <Fragment>
      <List configs={configs}/>
      {editable ? (
        <div style={{ display: 'flex' }}>
          <Button
            primary
            style={{ marginTop: '1.5em' }}
            onClick={toggle}
          >Edit</Button>
        </div>
      ) : false}
    </Fragment>
  )
}

export default CompanyConfigs