import { authFailure, authRequest, authSetEmail, authSetPassword, authSuccess } from './actions'

let formDefault = {
  email: '',
  password: ''
}

if (process.env.APP_ENV === 'development') {
  formDefault = {
    email: 'admin@runinall.com',
    password: '123'
  }
}

const initialState = {
  loading: false,
  form: formDefault,
  user: {
    name: '',
    email: '',
  },
  token: '',
  roles: [],
  errors: []
}

export default (state = initialState, { type, payload }) => {

  switch (type) {
    case authRequest.toString():
      return { ...state, loading: true }
    case authSuccess.toString():
      return { ...initialState, user: payload.user, roles: payload.roles, token: payload.token }
    case authFailure.toString():
      return { ...state, loading: false, errors: payload }
    case authSetEmail.toString():
      return { ...state, form: { ...state.form, email: payload } }
    case authSetPassword.toString():
      return { ...state, form: { ...state.form, password: payload } }
  }

  return state
}
