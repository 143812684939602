import React, { useState } from 'react'
import { Button, Header, Icon, Label, Modal } from 'semantic-ui-react'

const RemoveUserModal = ({ user, onRemove = () => {} }) => {
  const [open, setOpen] = useState(false)

  const onYes = () => {
    onRemove({user}).then(() => {
      setOpen(false)
    })
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      dimmer="blurring"
      size="small"
      trigger={(
        <Button icon basic size="mini" color="red" style={{ boxShadow: 'none' }}>
          <Icon size="large" name="close"/>
        </Button>
      )}
    >
      <Modal.Header>
        <Header content="Delete user"/>
      </Modal.Header>
      <Modal.Content>
        Do you really want to remove
        <Label size="small" color="teal" style={{margin: '0 0.5em'}}>
          {user.name}
          <Label.Detail>
            {user.email}
          </Label.Detail>
        </Label>
        ?
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove"/> No
        </Button>
        <Button color="green" inverted onClick={onYes}>
          <Icon name="checkmark"/> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RemoveUserModal