import { clearState, fetchQuotationsFailure, fetchQuotationsRequest, fetchQuotationsSuccess } from './actions'

const initialState = {
  loading: true,
  list: [],
  count: 0,
  limit: 10
}

export default (state = initialState, { type, payload }) => {

  switch (type) {
    case clearState.toString():
      return { ...initialState }
    case fetchQuotationsRequest.toString():
      return { ...state, loading: true }
    case fetchQuotationsSuccess.toString():
      return { ...state, loading: false, ...payload }
    case fetchQuotationsFailure.toString():
      return { ...state, loading: false }
  }

  return state
}
