import React, { Fragment, useEffect } from 'react'
import { Button, Icon, Label, Segment, Table } from 'semantic-ui-react'
import Moment from 'react-moment'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import Pagination from '@components/pagination'
import { QuotationFilter, QuotationStatus } from '@components/quotation'

const emptyCallback = () => {}

const AnswerStatus = ({ quotation }) => {

  if (quotation.products_count === quotation.answers_count) {
    return <Label color="green">{quotation.answers_count} / {quotation.products_count}</Label>
  }

  return <Label>{quotation.answers_count} / {quotation.products_count}</Label>
}

const OrdersStatus = ({ quotation }) => {

  if (quotation.products_count === quotation.orders_count) {
    return <Label color="green">{quotation.orders_count} / {quotation.products_count}</Label>
  }

  return <Label>{quotation.orders_count} / {quotation.products_count}</Label>
}

const List = ({ quotations = [], count }) => {

  const hasOrdersColumn = false;

  return (
    <Segment raised style={{ padding: '0' }}>
      <Table style={{ border: 'none' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={5}>Amount ({count})</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Reference</Table.HeaderCell>
            <Table.HeaderCell>Last update</Table.HeaderCell>
            <Table.HeaderCell>Answers</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            {hasOrdersColumn ? <Table.HeaderCell>Orders</Table.HeaderCell> : false}
            <Table.HeaderCell/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {quotations.map(quotation =>
            <Table.Row key={quotation.id}>
              <Table.Cell><Link to={`/quotation/${quotation.reference}`}>{quotation.reference}</Link></Table.Cell>
              <Table.Cell><Moment format="YYYY-MM-DD hh:mm A">{quotation.date_upd}</Moment></Table.Cell>
              <Table.Cell>
                <AnswerStatus quotation={quotation}/>
              </Table.Cell>
              <Table.Cell>
                <QuotationStatus status={quotation.status}/>
              </Table.Cell>
              {hasOrdersColumn
                ?
                <Table.Cell>
                  <OrdersStatus quotation={quotation}/>
                </Table.Cell>
                :
                false
              }
              <Table.Cell style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Link to={`/quotation/${quotation.reference}`}>
                  <Button animated>
                    <Button.Content visible>Details</Button.Content>
                    <Button.Content hidden>
                      <Icon name="arrow right"/>
                    </Button.Content>
                  </Button>
                </Link>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Segment>
  )
}

const QuotationList = ({
  loading = false,
  fetch = emptyCallback,
  clear = emptyCallback,
  quotations = [],
  count = 0,
  limit = 0,
  statuses = [],
  reference = '',
  company = '',
  filterCompanies = false
}) => {
  const dispatch = useDispatch()
  const { page = 1 } = useParams()
  const history = useHistory()

  let quotationLoading = false
  const loadQuotations = () => {
    quotationLoading = true
    dispatch(fetch({
        page,
        statuses: statuses.filter(v => !!v.checked).map(v => v.id),
        reference,
        company
      }
    ))

    return () => {
      dispatch(clear())
    }
  }

  // base effect
  useEffect(() => {
    return loadQuotations()
  }, [page, JSON.stringify(statuses.map(v => !!v.checked)), company])

  useEffect(() => {
    if (quotationLoading) {return }

    if (reference.length === 0) {
      loadQuotations()
    }

    if (reference.length < 2) { return }

    const timeout = setTimeout(() => {
      loadQuotations()
    }, 300)

    return () => {
      clearTimeout(timeout)
    }

  }, [reference])

  return (
    <Fragment>
      <QuotationFilter loading={loading} filterCompanies={filterCompanies} onChange={() => {
        if (page > 1) {
          history.push(`/quotations`)
        }
      }}/>

      <List loading={loading} quotations={quotations} count={count}/>

      <Pagination
        loading={loading}
        page={page}
        count={count}
        limit={limit}
        onPageChange={({ activePage }) => {
          history.push(`/quotations/${activePage}`)
        }}
      />

    </Fragment>
  )
}

export default QuotationList
