import React, { Fragment, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import { Redirect } from 'react-router'
import { emailVerify } from './actions'

const Error = ({ error }) => {
  if (error === false) {
    return false
  }

  return <Fragment>
    <p>Verification link is not found.</p>
    <p>Please <Link to="/login">login</Link> using your email and password to generate a new link.</p>
  </Fragment>
}

const Verify = ({}) => {
  const { hash } = useParams()

  const { loading, verified, error } = useSelector((state) => state.supplier.email.verify)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(emailVerify(hash))
  }, [hash])

  if (verified) {
    return <Redirect to="/login"/>
  }

  return <Fragment>
    <Loader active={loading} inline size="large">Verifying</Loader>
    <Error error={error}/>
  </Fragment>
}

const EmailVerifyPage = () => {
  return (
    <main>
      <div className="authentication-page__wrapper">
        <div className="background__wrapper background__wrapper_login-page">
          <div className="background-pattern background-pattern_login-page"/>
          <div className="text__container">
            <p className="title">Alone, we can do so little; together, we can do so much</p>
            <p>Helen Keller</p>
            <ul>
              <li>Created and designed by industry’s experts</li>
              <li>Connecting the industry</li>
              <li>Secure payment</li>
              <li>24/7 One stop shop</li>
            </ul>
          </div>
        </div>
        <div className="form__wrapper" style={{ background: 'white' }}>
          <div className="logo__container">
            <div className="logo-img"/>
          </div>
          <div className="form__container">
            <h2>Email verification</h2>
            <Verify/>
            <div className="link__container">
              <p>Already registered?</p>
              <Link to={'/login'}>Login here</Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default EmailVerifyPage
