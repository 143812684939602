import React from 'react'
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router'

const BackButton = () => {
  const history = useHistory()

  return <Button labelPosition="left" icon="left chevron" content="Back" onClick={() => {history.goBack()}}/>
}

export default BackButton
