import React, { Fragment, useEffect } from 'react'
import { Header, Placeholder, Segment, Table } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { BackButton, Products } from '@components'
import { QuotationHeader } from '@components/quotation'

import { Order } from './order-status'
import { cleanState, fetchQuotation } from './actions'

const Companies = ({ quotation, companies, loading }) => {
  if (loading) {
    return (
      <Products loading={loading}>
        <Table.Row>
          <Table.HeaderCell colSpan={4}>
            <Placeholder style={{ backgroundColor: 'inherit' }}>
              <Placeholder.Line length="short" style={{ backgroundColor: 'inherit' }}/>
            </Placeholder>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Placeholder style={{ backgroundColor: 'inherit' }}>
              <Placeholder.Line length="short" style={{ backgroundColor: 'inherit' }}/>
            </Placeholder>
          </Table.HeaderCell>
        </Table.Row>
      </Products>
    )
  }

  return companies.map(company => (
    <Products key={company.public_id} products={company.products} loading={loading}>
      <Table.Row>
        <Table.HeaderCell colSpan={4}>
          <Header as="h3" style={{ marginBottom: 0 }}>
            <Link to={`/company/${company.public_id}`}>{company.name}</Link>
          </Header>
        </Table.HeaderCell>
        <Table.HeaderCell>
          <Order company={company} quotation={quotation}/>
        </Table.HeaderCell>
      </Table.Row>
    </Products>
  ))
}

const Quotation = () => {
  const { id: quotationId } = useParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchQuotation(quotationId))

    return () => {
      dispatch(cleanState())
    }
  }, [quotationId])

  const { quotation, loading, companies } = useSelector((state) => state.shop.quotation)

  return (
    <Fragment>
      <BackButton/>

      <Segment.Group raised style={{ marginTop: '3em' }}>
        <QuotationHeader quotation={quotation} loading={loading}/>
        <Segment>
          <Companies quotation={quotation} companies={companies} loading={loading}/>
        </Segment>
      </Segment.Group>

    </Fragment>
  )
}

export default Quotation
