import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL_SUPPLIER } from '@config'

export const clearState = createAction('supplier/orders/clearState')

export const fetchOrdersRequest = createAction('supplier/orders/fetch/before')
export const fetchOrdersSuccess = createAction('supplier/orders/fetch/success')
export const fetchOrdersFailure = createAction('supplier/orders/fetch/failure')

export function fetchOrders ({ page, statuses, reference }) {
  return (dispatch) => {
    dispatch(fetchOrdersRequest())

    const params = [
      `page=${page}`,
      reference && `reference=${reference}`,
    ].filter(v => !!v).concat(
      statuses.map(s => `${encodeURIComponent('status[]')}=${s}`),
    ).join('&')

    axios.get(`${API_URL_SUPPLIER}/order?${params}`).then(({ data }) => {
      dispatch(fetchOrdersSuccess(data))
    }).catch(reason => {
      dispatch(fetchOrdersFailure(reason))
    })
  }
}
