import axios from 'axios'
import Cookies from 'universal-cookie/es6'

import { API_URL, UNAUTHORIZED_URL } from '@config'
import { history } from './history'
import { authSuccess } from '@login/actions'

function isPublicPage () {
  return /login|registration|email\/verify|forgot-password|update-password/.test(window.location.pathname)
}

function isLoginPage () {
  return /login/.test(window.location.pathname)
}

function gotoUnauthorizedPage () {
  if (isLoginPage() === false) {
    window.location.replace(UNAUTHORIZED_URL)
  }

  return true
}

function isAuthorized (token) {
  return axios.get(`${API_URL}/user/`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
}

function setUpAxios (token) {
  axios.interceptors.request.use(function (config) {
    let headers = {
      ...(config.headers || {}),
      Accept: 'application/json',
    }

    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    return { ...config, headers }
  })

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        if (isLoginPage() === false) {
          gotoUnauthorizedPage()
        }
      }

      return Promise.reject(error)
    }
  )
}

export function authenticate (email, password) {
  return axios.post(`${API_URL}/token/`, { email, password })
    .then((response) => {
      const { data: { token } } = response

      setUpAxios(token)

      return response
    })
}

export function setupAuth (store) {
  return new Promise((resolve, reject) => {
    const cookies = new Cookies()

    let token = cookies.get('sat')

    if (!token) {
      if (isPublicPage()) {
        resolve()
      } else {
        reject()
        gotoUnauthorizedPage()
      }

      return
    }

    isAuthorized(token)
      .then(({ data: { user, roles } }) => {
        setUpAxios(token)

        store.dispatch(authSuccess({ user, roles, token }))

        if (isPublicPage()) {
          history.replace('/')
        }

        resolve()
      })
      .catch(error => {
        if (error.response.status === 401) {
          if (isPublicPage()) {
            resolve()
          } else {
            reject()
            gotoUnauthorizedPage()
          }
        }

        reject()
        console.warn(error.response.data)
      })
  })
}
