import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL_SHOP } from '@config'

export const cleanState = createAction('shop/quotation/cleanState')

export const fetchQuotationRequest = createAction('shop/quotation/fetch/before')
export const fetchQuotationSuccess = createAction('shop/quotation/fetch/success')
export const fetchQuotationFailure = createAction('shop/quotation/fetch/failure')

export function fetchQuotation (id) {
  return (dispatch) => {
    dispatch(fetchQuotationRequest())

    return axios.get(`${API_URL_SHOP}/quotation/${id}`)
      .then(({ data }) => {
        dispatch(fetchQuotationSuccess(data))
      })
      .catch(reason => {
        dispatch(fetchQuotationFailure(reason))
      })
  }
}

export const createOrderRequest = createAction('shop/order/create/before')
export const createOrderSuccess = createAction('shop/order/create/success')
export const createOrderFailure = createAction('shop/order/create/failure')

export function createOrder ({ company, quotation_id, history }) {
  return (dispatch) => {
    dispatch(createOrderRequest({ company }))

    return axios.post(`${API_URL_SHOP}/order`, { company_public_id: company.public_id, quotation_id })
      .then(({ data }) => {
        dispatch(createOrderSuccess({ order: data, company }))

        history.push(`/order/${data.public_id}`)
      })
      .catch(reason => {
        dispatch(createOrderFailure({ reason, company }))
      })
  }
}
