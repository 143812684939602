import React, { Fragment } from 'react'
import { Header, Segment } from 'semantic-ui-react'
import { OrderReference, OrderStatus } from '@components/order'
import { Link } from 'react-router-dom'

const CompanyLink = ({ company: { name, public_id } = {} }) => {
  return (
    <Header as="h1" style={{ margin: '0' }}>
      <Link style={{ fontSize: '18px' }} to={`/company/${public_id}`}>{name}</Link>
    </Header>
  )
}

const QuotationLink = ({ quotation: { reference } = {} }) => {
  return (
    <Header as="h1" style={{ margin: '0' }}>
      <Link style={{ fontSize: '18px' }} to={`/quotation/${reference}`}>{reference}</Link>
    </Header>
  )
}

const OrderHeader = ({ loading, order: { reference = '', status = {} } = {}, company, quotation }) => {

  const segmentStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }

  return (
    <Fragment>
      <Segment style={segmentStyle}>
        <Header as="h1" style={{ margin: '0' }}>Order</Header>
        <OrderReference loading={loading} reference={reference}/>
        <OrderStatus loading={loading} size="big" status={status}/>
      </Segment>
      <Segment style={segmentStyle}>
        {company ? (
          <CompanyLink company={company}/>
        ) : (
          <Header as="h1" style={{ margin: '0' }}>Quotation</Header>
        )}
        <QuotationLink quotation={quotation}/>
      </Segment>
    </Fragment>
  )
}

export default OrderHeader
