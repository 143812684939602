import React, { Fragment, useEffect } from 'react'
import { Segment, Table } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { BackButton, Products } from '@components'
import { OrderHeader, OrderHistory } from '@components/order'
import { fetchOrder } from './actions'
import { ChangeStatus } from '@shop/orders/order/status'

const Order = () => {
  const { id: orderId } = useParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchOrder(orderId))
  }, [orderId])

  const { order, history, company, quotation, loading } = useSelector((state) => state.shop.order)
  const { products = [] } = quotation

  return (
    <Fragment>
      <BackButton/>

      <Segment.Group raised style={{ marginTop: '3em' }}>
        <OrderHeader order={order} company={company} quotation={quotation} loading={loading}/>
        <Segment>
          <Products products={products}>
            <Table.Row>
              <Table.HeaderCell colSpan={5}>Amount ({products.length})</Table.HeaderCell>
            </Table.Row>
          </Products>
        </Segment>

        <ChangeStatus order={order} onSubmit={() => {dispatch(fetchOrder(orderId))}}/>
      </Segment.Group>

      <OrderHistory loading={loading} history={history}/>

    </Fragment>
  )
}

export default Order
