import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL_SHOP } from '@config'

export const clearState = createAction('shop/quotations/clearState')

export const fetchQuotationsRequest = createAction('shop/quotations/fetch/before')
export const fetchQuotationsSuccess = createAction('shop/quotations/fetch/success')
export const fetchQuotationsFailure = createAction('shop/quotations/fetch/failure')

export function fetchQuotations ({ page, statuses, reference, company }) {
  return (dispatch) => {
    dispatch(fetchQuotationsRequest())

    const params = [
      `page=${page}`,
      reference && `reference=${reference}`,
      company && `company=${company}`,
    ].filter(v => !!v).concat(
      statuses.map(s => `${encodeURIComponent('status[]')}=${s}`)
    ).join('&')

    axios.get(`${API_URL_SHOP}/quotation?${params}`)
      .then(({ data }) => {
        dispatch(fetchQuotationsSuccess(data))
      })
      .catch(reason => {
        dispatch(fetchQuotationsFailure(reason))
      })
  }
}
