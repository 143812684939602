import { Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { matchPath } from 'react-router'
import React from 'react'

const MenuItem = ({ path, paths = [], hide = false, children }) => {
  const pathname = location.pathname
  const pathsCombined = [path, ...paths]

  if (hide) {return false}

  return <Menu.Item
    as={Link}
    active={pathsCombined.reduce((carry, item) => carry || !!(matchPath(pathname, item) || {}).isExact, false)}
    to={path}>{children}</Menu.Item>
}

export default MenuItem
