import {
  hashInfoFailure,
  hashInfoRequest, hashInfoSuccess,
  registerFailure,
  registerRequest,
  registerSuccess,
  setUserEmail,
  setUserName,
  setUserPassword,
  setUserPasswordConfirm,
} from './actions'

const initialState = {
  loading: true,
  user: {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
  },
  domain: '',
  messages: [],
  errorsRegister: {},
  errorsHashInfo: [],
  created: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case hashInfoRequest.toString():
      return { ...initialState }
    case hashInfoFailure.toString():
      return { ...state, loading: false, errorsHashInfo: payload }
    case hashInfoSuccess.toString():
      return { ...initialState, loading: false, domain: payload.domain }

    case registerRequest.toString():
      return { ...state, loading: true, errorsRegister: {} }
    case registerFailure.toString():
      return { ...state, loading: false, errorsRegister: payload }
    case registerSuccess.toString():
      return { ...initialState, domain: state.domain, loading: false, messages: payload.messages, created: true }

    case setUserName.toString():
      return { ...state, user: { ...state.user, name: payload } }
    case setUserEmail.toString():
      return { ...state, user: { ...state.user, email: payload } }
    case setUserPassword.toString():
      return { ...state, user: { ...state.user, password: payload } }
    case setUserPasswordConfirm.toString():
      return { ...state, user: { ...state.user, password_confirmation: payload } }
  }

  return state
}
