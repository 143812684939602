import { resetPasswordRequest, resetPasswordSuccess, resetPasswordFailure, setEmail } from './actions'

const initialState = {
  loading: false,
  email: '',
  success: false,
  errors: []
}

export default (state = initialState, { type, payload }) => {

  switch (type) {
    case resetPasswordRequest.toString():
      return { ...state, loading: true }
    case resetPasswordSuccess.toString():
      return { ...initialState, success: payload, loading: false }
    case resetPasswordFailure.toString():
      return { ...state, loading: false, errors: payload }
    case setEmail.toString():
      return { ...state, email: payload }
  }

  return state
}
