import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { history } from '../history'
import login from '@login/reducer'
import forgot from '@forgot/reducer'
import update from '@forgot/update/reducer'
import shop from '@shop/reducer'
import supplier from '@supplier/reducer'
import quotationFilter from '@components/quotation/filter/reducer'
import orderFilter from '@components/order/filter/reducer'

export default combineReducers({
  router: connectRouter(history),
  login,
  forgot,
  update,
  shop,
  supplier,
  quotationFilter,
  orderFilter,
})
