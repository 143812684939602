import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'

import rootReducer from '../reducer'
import { history } from '../history'

export default (initialState) => createStore(
  rootReducer,
  initialState,
  applyMiddleware(
    thunkMiddleware,
    routerMiddleware(history),
  )
)
