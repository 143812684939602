import { createAction } from 'redux-actions'

import { authenticate } from '../../app/auth'
import axios from 'axios'
import { API_URL, UNAUTHORIZED_URL } from '../../config/environments'

export const authRequest = createAction('auth/request')
export const authSuccess = createAction('auth/success')
export const authFailure = createAction('auth/failure')

export const authSetEmail = createAction('auth/setEmail')
export const authSetPassword = createAction('auth/setPassword')

export function logout () {
  return () => {
    return axios.post(`${API_URL}/token/remove`)
      .then(() => {
        window.location.replace(UNAUTHORIZED_URL)
      })
  }
}

export function auth ({ email, password }) {

  return (dispatch) => {
    dispatch(authRequest())

    authenticate(email, password)
      .then(({ data }) => {
        dispatch(authSuccess(data))
      })
      .catch(error => {
        if (error.response.status !== 401) {
          console.warn('Unexpected response status')
          //todo
          return
        }

        dispatch(authFailure([error.response.data.message]))
      })
  }
}
