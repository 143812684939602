import React from 'react'
import { Form, Input } from 'semantic-ui-react-form-validator'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  registerCompany,
  setCompanyDomain,
  setCompanyName,
  setUserEmail,
  setUserName,
  setUserPassword,
  setUserPasswordConfirm
} from './actions'

import { Input as SemanticInput } from 'semantic-ui-react'

const Errors = ({ errors = [] }) => {
  if (errors.length === 0) {
    return false
  }

  return errors.map(e => <div>{e}</div>)
}

const RegisterForm = () => {
  const dispatch = useDispatch()

  const { company, user, errors, created, messages } = useSelector((state) => state.supplier.register)

  const { name: companyName, domain } = company
  const { name, email, password, password_confirmation } = user

  const register = () => {
    dispatch(registerCompany({ company, user: { ...user, email: `${user.email}@${company.domain}` } }))
  }

  if (created) {
    return messages.map((message, i) => (
      <p key={i}>{message}</p>
    ))
  }

  return (
    <Form size="large" onSubmit={register}>
      <Input
        type="text"
        placeholder="Company name"
        value={companyName}
        onChange={({ target: { value } }) => dispatch(setCompanyName(value))}
        fluid
        required
        width="12"
        style={{ borderRadius: '0', height: '50px' }}
      />
      <Errors errors={errors['company.name']}/>

      <SemanticInput
        type="text"
        placeholder="Company domain (e.g. runinall.com)"
        value={domain}
        onChange={({ target: { value } }) => dispatch(setCompanyDomain(value))}
        fluid
        required
        width="12"
        style={{ borderRadius: '0', marginBottom: '15px', width: '390px', height: '50px' }}
        label="https://"
      />
      <Errors errors={errors['company.domain']}/>

      <Input
        type="text"
        placeholder="Your name"
        value={name}
        onChange={({ target: { value } }) => dispatch(setUserName(value))}
        fluid
        required
        width="12"
        style={{ borderRadius: '0', height: '50px' }}
      />
      <Errors errors={errors['user.name']}/>

      <SemanticInput
        type="text"
        placeholder="E-mail address"
        value={email}
        onChange={({ target: { value } }) => dispatch(setUserEmail(value))}
        fluid
        required
        width="6"
        style={{ borderRadius: '0', marginBottom: '15px', width: '390px', height: '50px' }}
        label={`@${domain}`}
        labelPosition="right"
      />
      <Errors errors={errors['user.email']}/>

      <Input
        placeholder="Password"
        type="password"
        value={password}
        onChange={({ target: { value } }) => dispatch(setUserPassword(value))}
        fluid
        required
        width="12"
        style={{ borderRadius: '0', height: '50px' }}
      />
      <Errors errors={errors['user.password']}/>

      <Input
        placeholder="Repeat password"
        type="password"
        value={password_confirmation}
        onChange={({ target: { value } }) => dispatch(setUserPasswordConfirm(value))}
        fluid
        required
        width="12"
        style={{ borderRadius: '0', height: '50px' }}
      />
      <Errors errors={errors['user.password_confirmation']}/>

      <button className="button-primary">Register</button>
    </Form>
  )
}

const RegistrationPage = () => {
  const { token } = useSelector((state) => state.login)

  if (token !== '') {
    return <Redirect to="/"/>
  }

  return (
    <main>
      <div className="authentication-page__wrapper">
        <div className="background__wrapper background__wrapper_register-page">
          <div className="background-pattern background-pattern_register-page"/>
          <div className="text__container">
            <p className="title">There is strength in independence but there is power in community.</p>
            <p>Dr. Madeline Ofina</p>
            <ul>
              <li>Created and designed by industry’s experts</li>
              <li>Connecting the industry</li>
              <li>Secure payment</li>
              <li>24/7 One stop shop</li>
            </ul>
          </div>
        </div>
        <div className="form__wrapper">
          <div className="logo__container">
            <div className="logo-img"/>
          </div>
          <div className="form__container">
            <h2>Create your company account</h2>
            <RegisterForm/>
            <div className="link__container">
              <p>Already registered?</p>
              <Link to={'/login'}>Login here</Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default RegistrationPage
