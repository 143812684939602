import {
  clearState,
  companySetSelectedSupplier,
  companyUpdateSetChecked,
  companyUpdateSetEnabled,
  companyUpdateSetSupplier,
  fetchCompanyFailure,
  fetchCompanyRequest,
  fetchCompanySuccess,
  fetchSuppliersFailure,
  fetchSuppliersRequest,
  fetchSuppliersSuccess,
} from './actions'

const initialState = {
  loading: true,
  company: {},
  selectedSupplier: null,
  suppliers: [],
  usersCount: 0,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case clearState.toString():
      return { ...initialState }
    case fetchCompanyRequest.toString():
      return { ...state, loading: true }
    case fetchCompanySuccess.toString(): {
      const configs = (payload.configs || []).reduce((carry, config) => ({
        ...carry,
        [config.name]: config.value,
      }), {})

      return { ...state, loading: false, company: { ...payload, configs } }
    }
    case fetchCompanyFailure.toString():
      return { ...state, loading: false }
    case fetchSuppliersRequest.toString():
      return { ...state, loading: true }
    case fetchSuppliersSuccess.toString():
      return { ...state, loading: false, suppliers: payload }
    case fetchSuppliersFailure.toString():
      return { ...state, loading: false }
    case companySetSelectedSupplier.toString():
      return { ...state, loading: false, selectedSupplier: payload }
    case companyUpdateSetSupplier.toString():
      return {
        ...state,
        loading: false,
        company: { ...state.company, supplier: payload },
      }
    case companyUpdateSetEnabled.toString():
      return { ...state, company: { ...state.company, enabled: payload } }
    case companyUpdateSetChecked.toString():
      return { ...state, company: { ...state.company, checked: payload } }
  }

  return state
}
