import React, { Fragment } from 'react'
import { Button, Header, Segment } from 'semantic-ui-react'

const State = ({
  checkedText,
  uncheckedText,
  loading = true,
  checked = true,
  editable = false,
  change = () => {}
}) => {

  let style = loading === false ? {
    backgroundColor: checked ? '#21ba45' : '#b21e1e',
    color: 'white',
    border: 'none',
    cursor: editable ? 'pointer' : 'initial'
  } : { border: 'none', cursor: editable ? 'pointer' : 'initial' }

  return (
    <Button
      size="small"
      loading={loading}
      disabled={loading}
      style={style}
      onClick={() => editable && change()}
    >{checked ? checkedText : uncheckedText}</Button>
  )
}

const CompanyHeader = ({
  loading = true,
  editable = false,
  checkedChange = () => {},
  enabledChange = () => {},
  company: { name, domain, enabled = false, checked = false } = {}
}) => {

  const segmentStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }

  return (
    <Fragment>
      <Segment style={segmentStyle}>
        <Header as="h1" style={{ margin: '0 2em 0 0' }}>{name}</Header>
        <div>
          <State loading={loading} checked={enabled} change={enabledChange} editable={editable}
                 checkedText="Enabled"
                 uncheckedText="Disabled"
          />
          <State loading={loading} checked={checked} change={checkedChange} editable={editable && !checked}
                 checkedText="Checked"
                 uncheckedText="Not validated"
          />
        </div>
      </Segment>
      <Segment style={{ borderTop: '0' }}>
        <Header as="h4" style={{ margin: '0' }}><a href={`https://${domain}`} target="_blank">{`https://${domain}`}</a></Header>
      </Segment>
    </Fragment>
  )
}

export default CompanyHeader