import React from 'react'
import { Image, Label } from 'semantic-ui-react'
import { Form, Input } from 'semantic-ui-react-form-validator'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword, setEmail } from './actions'
import logoBlack from '@static/logoBlack.svg'

const Errors = ({ errors = [] }) => {
  if (!errors.length) {
    return false
  }

  return (
    <div className="errors" style={{ width: '100%' }}>
      {errors.map((error, index) =>
        <Label color="red" key={index} style={{
          color: 'white',
          width: '100%',
          marginTop: '15px',
          fontSize: '16px',
          borderRadius: '0',
        }}>{error}</Label>
      )}
    </div>
  )
}

export const ResetPasswordForm = () => {
  const dispatch = useDispatch()

  const { email, success, errors } = useSelector((state) => state.forgot)

  const goReset = () => {
    dispatch(resetPassword({ email }))
  }

  if (success) {
    return (
      <h3>Message sent ! Check your email</h3>
    )
  } else {
    return (
      <div className={'form__container'} style={{marginLeft: '0'}}>
        <h2 style={{paddingTop: '15px', fontSize: '36px'}}>Recover Password</h2>
        <Form size="large" onSubmit={goReset}>
          <Input
            onChange={({ target: { value } }) => dispatch(setEmail(value))}
            value={email}
            type="email"
            fluid
            iconPosition="left" placeholder="E-mail address"
            required
            width="8"
            style={{ borderRadius: '0', height: '50px' }}
          />
          <p className={'info-text'}>We will send you the instructions how to recover your password to this email</p>
          <Errors errors={errors}/>
          <button className="button-primary">Email me a recovery link</button>
        </Form>
      </div>
    )
  }
}

const ResetPasswordPage = () => {
  return (
    <main className={'page_password'}>
      <div className={'auth__container'}>
        <div className={'authentication-page__wrapper recover-password-page'}>
          <div className={'form__wrapper'}>
            <div className={'logo__container'} style={{marginLeft: '0'}}>
              <Image className={'logo-img'} style={{height: '100px'}} src={logoBlack} />
            </div>
            <ResetPasswordForm/>
            <div className={'link__container'}>
              <Link to="/login">Back to login</Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ResetPasswordPage
