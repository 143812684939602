import React from 'react'
import { useSelector } from 'react-redux'

import { QuotationList } from '@components/quotation'
import { fetchQuotations, clearState } from './actions'

const Quotations = () => {

  const { loading, list: quotations, count, limit } = useSelector((state) => state.shop.quotations)
  const { statuses, reference, company } = useSelector((state) => state.quotationFilter)

  return (
    <QuotationList
      loading={loading}
      quotations={quotations}
      statuses={statuses}
      reference={reference}
      company={company}
      filterCompanies={true}
      count={count}
      limit={limit}
      fetch={fetchQuotations}
      clear={clearState}
    />
  )
}

export default Quotations
