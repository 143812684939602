import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL } from '@config'

export const setEmail = createAction('forgot-password/setEmail')

export const resetPasswordRequest = createAction('forgot-password/before')
export const resetPasswordSuccess = createAction('forgot-password/success')
export const resetPasswordFailure = createAction('forgot-password/failure')

export function resetPassword ({ email }) {

  return (dispatch) => {
    dispatch(resetPasswordRequest())

    return axios.post(`${API_URL}/reset-password`, {email})
      .then(({ data }) => {
        dispatch(resetPasswordSuccess(data.success))
      })
      .catch(error => {
        if (error.response.status === 422) {
          if (error.response.data.message) {
            dispatch(resetPasswordFailure([error.response.data.message]))
          } else if (error.response.data.messages) {
            dispatch(resetPasswordFailure(error.response.data.messages))
          }
        }
      })
  }
}
