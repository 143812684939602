import React, { useState } from 'react'
import { Button, Checkbox, Form, Header, Icon, Input, Label, Message, Modal } from 'semantic-ui-react'

const FormLabel = ({ width = 7, name, style = {}, children }) => {

  return (
    <Form.Field width={width} style={style}>
      <label style={{ fontSize: 'inherit', margin: '0' }}>
        <Header style={{ marginBottom: '0.3em' }} as="h5">{name}:</Header>
        {children}
      </label>
    </Form.Field>
  )
}

const InvitationInput = ({
  hidden = false,
  width = 7,
  name,
  placeholder,
  value,
  label,
  labelPosition,
  style = {},
  onChange = () => {}
}) => {
  if (hidden) {return false}

  let properties = {}

  if (typeof value !== 'undefined') {
    properties.value = value
  }

  if (typeof onChange !== 'undefined') {
    properties.onChange = onChange
  }

  if (typeof label !== 'undefined') {
    properties.label = label
  }

  if (typeof labelPosition !== 'undefined') {
    properties.labelPosition = labelPosition
  }

  return (
    <FormLabel width={width} name={name} style={style}>
      <Input
        type="text"
        fluid
        required
        placeholder={placeholder || name}
        {...properties}
      />
    </FormLabel>
  )
}

const GenerateInvitationModal = ({ company, loading = true, onSave = () => {} }) => {
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [forDomain, setForDomain] = useState(false)
  const [link, setLink] = useState('')
  const [messages, setMessages] = useState([])
  const [invitationSent, setInvitationSent] = useState(false)

  const onSend = () => {

    if (forDomain) {
      onSave({ domain: true }).then(({ invitation_url }) => {
        setMessages([])
        setLink(invitation_url)
      })
        .catch(({ response: { data: { messages = [] } = {} } } = {}) => {
          setMessages(messages)
          setInvitationSent(false)
          setLink('')
        })
    } else {
      onSave({ email: `${email}@${company.domain}` }).then(() => {
        setMessages([])
        setInvitationSent(true)
      })
        .catch(({ response: { data: { messages = [] } = {} } } = {}) => {
          setMessages(messages)
          setInvitationSent(false)
          setLink('')
        })
    }
  }

  const switchType = () => {
    setForDomain(!forDomain)
    setEmail('')
    setInvitationSent(false)
    setLink('')
  }

  const close = () => {
    setOpen(false)
    setEmail('')
    setInvitationSent(false)
    setLink('')
  }

  return (
    <Modal
      className="invitation"
      closeOnEscape={false}
      closeOnDimmerClick={!loading}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      dimmer="blurring"
      size="small"
      trigger={(
        <Button size="small" color="teal" content="Generate invitation"/>
      )}
    >
      <Modal.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Header content="Generate invitation"/>
      </Modal.Header>
      <Modal.Content>
        <Form
          size="small"
          error={!!messages.length}
          success={invitationSent}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between'
          }}
        >

          <FormLabel name="For domain">
            <Checkbox toggle
                      style={{ border: 'none' }}
                      disabled={loading || !!link}
                      onChange={switchType}
            />
          </FormLabel>

          {forDomain ? (
            <FormLabel name="For domain" width={16}>
              {link === '' ? (
                <Label size="large">Link will appear hear</Label>
              ) : (
                <Label size="large" color="teal">{link}</Label>
              )}
            </FormLabel>
          ) : (
            <InvitationInput
              name="Send to E-Mail"
              placeholder="john.doe"
              label={`@${company.domain}`}
              labelPosition="right"
              disabled={loading}
              value={email}
              onChange={(e, { value }) => setEmail(value)}
            />
          )}

          {messages.length
            ? <Message error list={messages}/>
            : false
          }

          {invitationSent
            ? <Message success list={['Invitation link successfully sent']}/>
            : false
          }

        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" loading={loading} inverted onClick={close}>
          <Icon name="remove"/> Close
        </Button>
        <Button color="green" loading={loading} inverted disabled={!!link || invitationSent} onClick={onSend}>
          <Icon name="checkmark"/> {forDomain ? 'Generate' : 'Send'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default GenerateInvitationModal