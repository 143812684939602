import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Segment, } from 'semantic-ui-react'

import { updateOrderStatus } from './actions'

export const ChangeStatus = ({ order, onSubmit = () => {} }) => {
  const dispatch = useDispatch()

  const { public_id, status_id } = order
  const { loading } = useSelector((state) => state.supplier.order)

  const updateStatus = ({ status }) => {
    dispatch(updateOrderStatus({ id: public_id, status })).then(() => {
      onSubmit()
    })
  }

  if (status_id === 2) {
    return (
      <Segment>
        <Button
          loading={loading}
          size="small"
          style={{ color: 'white', backgroundColor: '#4169E1' }}
          onClick={() => updateStatus({ status: 3 })}
        >Mark as Acknowledged</Button>
      </Segment>
    )
  }

  if (status_id === 3) {
    return (
      <Segment>
        <Button
          loading={loading}
          size="small"
          style={{ color: 'white', backgroundColor: '#F3CD43' }}
          onClick={() => updateStatus({ status: 4 })}
        >Mark as Manufacturing</Button>
      </Segment>
    )
  }

  if (status_id === 4) {
    return (
      <Segment>
        <Button
          loading={loading}
          size="small"
          style={{ color: 'white', backgroundColor: '#108510' }}
          onClick={() => updateStatus({ status: 5 })}
        >Mark as "Ready for collection"</Button>
      </Segment>
    )
  }

  return false
}

export default ChangeStatus
