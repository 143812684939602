import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL_SHOP } from '@config'

export const fetchCompaniesRequest = createAction('companies/fetch/before')
export const fetchCompaniesSuccess = createAction('companies/fetch/success')
export const fetchCompaniesFailure = createAction('companies/fetch/failure')

export function fetchCompanies () {
  return (dispatch) => {
    dispatch(fetchCompaniesRequest())

    axios.get(`${API_URL_SHOP}/company`)
      .then(({ data }) => {
        dispatch(fetchCompaniesSuccess(data))
      })
      .catch(reason => {
        dispatch(fetchCompaniesFailure(reason))
      })
  }
}
