import { fetchProductsFailure, fetchProductsRequest, fetchProductsSuccess, setPage } from './actions'
import { createProductUrl } from '../../../util'

const initialState = {
  loading: false,
  page: 1,
  list: [],
  count: 0,
  perPage: 10
}

export default function (state = initialState, { type, payload }) {

  switch (type) {
    case setPage.toString():
      return { ...state, page: payload }
    case fetchProductsRequest.toString():
      return { ...state, loading: true }
    case fetchProductsSuccess.toString():
      return {
        ...state,
        loading: false,
        list: payload.list.map((v) => ({ ...v, url: createProductUrl(v) })),
        count: payload.count,
        perPage: payload.per_page
      }
    case fetchProductsFailure.toString():
      return { ...state, loading: false }
  }

  return state
}
