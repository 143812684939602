import React from 'react'
import { useSelector } from 'react-redux'

import { OrderList } from '@components/order'
import { clearState, fetchOrders } from './actions'

const Quotations = () => {

  const { loading, list: orders, count, limit } = useSelector((state) => state.shop.orders)
  const { statuses, reference, company } = useSelector((state) => state.orderFilter)

  return (
    <OrderList
      loading={loading}
      orders={orders}
      statuses={statuses}
      reference={reference}
      company={company}
      filterCompanies={true}
      count={count}
      limit={limit}
      fetch={fetchOrders}
      clear={clearState}
    />
  )
}

export default Quotations
