import React from 'react'
import { Header } from 'semantic-ui-react'

const OrderReference = ({ loading, reference }) => {

  if (loading) {
    return (
      <Header as="h1"
              style={{
                margin: '0',
                color: 'rgb(216 197 197 / 60%)',
                position: 'relative',
              }}>
        REFERENCE
        {/*<Loader active={true}/>*/}
      </Header>
    )
  }

  return <Header as="h1" style={{ margin: '0' }}>{reference}</Header>
}

export default OrderReference