import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL, API_URL_SHOP } from '@config'

export const toggleChecked = createAction('quotation/status/toggle-checked')
export const changeReference = createAction('quotation/filter/changeReference')
export const changeCompany = createAction('quotation/filter/changeCompany')

export const fetchStatusRequest = createAction('quotation/status/fetch/before')
export const fetchStatusSuccess = createAction('quotation/status/fetch/success')
export const fetchStatusFailure = createAction('quotation/status/fetch/failure')

export function fetchQuotationStatus () {
  return (dispatch) => {
    dispatch(fetchStatusRequest())

    axios.get(`${API_URL}/quotation/status`)
      .then(({ data }) => {
        dispatch(fetchStatusSuccess(data))
      })
      .catch(reason => {
        dispatch(fetchStatusFailure(reason))
      })
  }
}

export const fetchCompaniesRequest = createAction('quotation/filter/companies/fetch/before')
export const fetchCompaniesSuccess = createAction('quotation/filter/companies/fetch/success')
export const fetchCompaniesFailure = createAction('quotation/filter/companies/fetch/failure')

export function fetchCompanies () {
  return (dispatch) => {
    dispatch(fetchCompaniesRequest())

    axios.get(`${API_URL_SHOP}/quotation/companies`)
      .then(({ data }) => {
        dispatch(fetchCompaniesSuccess(data))
      })
      .catch(reason => {
        dispatch(fetchCompaniesFailure(reason))
      })
  }
}