import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import configureStore from './app/store'
import { history } from './app/history'
import Routes from './app/router'
import { setupAuth } from './app/auth'
import axios from 'axios'

(function () {
  const store = configureStore()

  // https://stackoverflow.com/questions/59808537/cookies-headers-are-present-but-cookies-are-not-stored-in-browser
  axios.defaults.withCredentials = true

  setupAuth(store).then(() => {

    render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Routes/>
        </ConnectedRouter>
      </Provider>,
      document.getElementById('root')
    )
  })
})()
