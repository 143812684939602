import React, { Fragment } from 'react'
import { Route } from 'react-router'
import { Link } from 'react-router-dom'
import { Image, Menu } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'

import { logout } from '@login/actions'

import Companies from '../../ui/shop/companies'
import Company from '../../ui/shop/companies/company'
import Quotations from '../../ui/shop/quotations'
import Quotation from '../../ui/shop/quotations/quotation'
import Orders from '../../ui/shop/orders'
import Order from '../../ui/shop/orders/order'
import logo from '../../../static/logo.svg'

function Home () {
  return false
}

const ShopMenuLayout = ({ children }) => {
  const dispatch = useDispatch()

  return (
    <div style={{ display: 'flex' }}>
      <Menu inverted vertical fixed="left"
            style={{ height: '100vh', borderRadius: 'inherit', margin: 0, backgroundColor: '#172f48' }}>
        <Menu.Item header>
          <Image src={logo} style={{ marginRight: '1.5em', width: '100%' }}/>
        </Menu.Item>
        <Menu.Item as={Link} to="/companies">Companies</Menu.Item>
        <Menu.Item as={Link} to="/quotations">Quotations</Menu.Item>
        <Menu.Item as={Link} to="/orders">Orders</Menu.Item>
        <Menu.Item onClick={() => dispatch(logout())}>Logout</Menu.Item>
      </Menu>
      <div id="main-content" style={{ margin : '20px 40px 50px 250px' }}>
        {children}
      </div>
    </div>
  )
}

const ShopRoute = ({ component: Component, path: path }) => {
  return (
    <Route exact path={path}>
      <ShopMenuLayout>
        <Component/>
      </ShopMenuLayout>
    </Route>
  )
}

export default function () {
  return (
    <Fragment>
      <ShopRoute path="/" component={Home}/>
      <ShopRoute path="/companies" component={Companies}/>
      <ShopRoute path="/company/:id" component={Company}/>
      <ShopRoute path="/quotations/:page?" component={Quotations}/>
      <ShopRoute path="/quotation/:id" component={Quotation}/>
      <ShopRoute path="/orders/:page?" component={Orders}/>
      <ShopRoute path="/order/:id" component={Order}/>
    </Fragment>
  )
}
