import { createStore } from 'redux'

let configureStore = (initialState) => createStore(
  (state) => state,
  initialState
)

if (process.env.NODE_ENV === 'production') {
  configureStore = require('./prod').default
} else {
  configureStore = require('./dev').default
}

export default configureStore
