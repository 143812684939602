import { Label, Placeholder, Table } from 'semantic-ui-react'
import React, { Fragment } from 'react'

const Name = ({ product }) => {

  if (product.attributes.length) {
    return (
      <Fragment>
        <a href={product.url} target="_blank">{product.name}</a>
        <span style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '5px',
        }}>
          {product.attributes.map(attr => (
            <span key={attr.group_public}>{attr.group_public}: <span>{attr.name}</span></span>
          ))}
        </span>
      </Fragment>
    )
  }

  return <a href={product.url} target="_blank">{product.name}</a>
}

const Price = ({ product: { price_tax_excl } }) => {
  if (!price_tax_excl) {
    return false
  }

  const price = parseFloat(price_tax_excl).toFixed(2)

  return (
    <Label style={{ fontSize: '1rem' }}> <span>$</span> {price}    </Label>
  )
}

const Product = ({ product, button }) => {

  const buttonWithProduct = (button) => {
    if (!button) { return false}

    if (React.isValidElement(button)) {
      return <Table.Cell style={{ width: '163px' }}>{React.cloneElement(button, { product })}</Table.Cell>
    }

    return false
  }

  return (
    <Table.Row>
      <Table.Cell style={{ width: '320px' }}><Name product={product}/></Table.Cell>
      <Table.Cell style={{ width: '163px' }}>{product.supplier_reference ||
      '-'}</Table.Cell>
      <Table.Cell style={{ width: '163px' }}>{product.amount}</Table.Cell>
      <Table.Cell style={{ width: '163px' }}><Price product={product}/></Table.Cell>
      <Table.Cell style={{ width: '163px' }}>{product.delivery_time}</Table.Cell>
      {buttonWithProduct(button)}
    </Table.Row>
  )
}

const ProductLoading = ({ button }) => (
  <Table.Row>
    <Table.Cell style={{ width: '320px' }}>
      <Placeholder>
        <Placeholder.Line length="short"/>
      </Placeholder>
    </Table.Cell>
    <Table.Cell style={{ width: '163px' }}>
      <Placeholder>
        <Placeholder.Line length="short"/>
      </Placeholder>
    </Table.Cell>
    <Table.Cell style={{ width: '163px' }}>
      <Placeholder>
        <Placeholder.Line length="short"/>
      </Placeholder>
    </Table.Cell>
    <Table.Cell style={{ width: '163px' }}>
      <Placeholder>
        <Placeholder.Line length="short"/>
      </Placeholder>
    </Table.Cell>
    <Table.Cell style={{ width: '163px' }}>
      <Placeholder>
        <Placeholder.Line length="short"/>
      </Placeholder>
    </Table.Cell>
    {button ? (
      <Placeholder>
        <Placeholder.Line length="short"/>
      </Placeholder>
    ) : false}
  </Table.Row>
)

const Products = ({ products = [], children, loading, button, style = {} }) => {

  return (
    <Table style={style}>
      <Table.Header>
        {children}
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Reference</Table.HeaderCell>
          <Table.HeaderCell>Quantity</Table.HeaderCell>
          <Table.HeaderCell>Price</Table.HeaderCell>
          <Table.HeaderCell>Delivery time</Table.HeaderCell>
          {button ? <Table.HeaderCell/> : false}
        </Table.Row>
      </Table.Header>
      <Table.Body>

        {loading ? <ProductLoading/> : products.map(product =>
          <Product key={product.key}
                   product={product}
                   button={button || false}/>,
        )}

      </Table.Body>
    </Table>
  )
}

export default Products
