import { createAction } from 'redux-actions'
import axios from 'axios'
import { API_URL_SUPPLIER } from '../../../config/environments'

export const setPage = createAction('products/setPage')

export const fetchProductsRequest = createAction('products/fetch/before')
export const fetchProductsSuccess = createAction('products/fetch/success')
export const fetchProductsFailure = createAction('products/fetch/failure')

export function fetchProducts ({ page }) {
  return (dispatch) => {
    dispatch(fetchProductsRequest())

    axios.get(`${API_URL_SUPPLIER}/product?page=${page}`)
      .then(({ data }) => {
        dispatch(fetchProductsSuccess(data))
      })
      .catch(reason => {
        dispatch(fetchProductsFailure(reason))
      })
  }
}
