import React, { useEffect } from 'react'
import { Form, Input } from 'semantic-ui-react-form-validator'
import { useParams } from 'react-router-dom'
import { Redirect } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { hashInfo, register, setUserEmail, setUserName, setUserPassword, setUserPasswordConfirm } from './actions'

import { Input as SemanticInput } from 'semantic-ui-react'

const Errors = ({ errors = [] }) => {
  if (errors.length === 0) {
    return false
  }

  return errors.map(e => <div>{e}</div>)
}

const RegisterForm = ({ hash }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(hashInfo({ hash }))
  }, [hash])

  const {
    loading,
    user,
    domain,
    errorsRegister: errors,
    created,
    messages = []
  } = useSelector((state) => state.supplier.invitation)

  const registerUser = () => {
    dispatch(register({ hash, user: { ...user, email: `${user.email}@${domain}` } }))
  }

  if (created) {
    return messages.map((message, i) => (
      <p key={i}>{message}</p>
    ))
  }

  return (
    <Form size="large" onSubmit={registerUser}>

      <Input
        type="text"
        placeholder="Your name"
        value={user.name}
        onChange={({ target: { value } }) => dispatch(setUserName(value))}
        fluid
        required
        width="12"
        style={{ borderRadius: '0', height: '50px' }}
      />
      <Errors errors={errors['user.name']}/>

      <SemanticInput
        type="text"
        placeholder="E-mail address"
        value={user.email}
        onChange={({ target: { value } }) => dispatch(setUserEmail(value))}
        fluid
        required
        width="6"
        style={{ borderRadius: '0', marginBottom: '15px', width: '390px', height: '50px' }}
        label={`@${domain}`}
        labelPosition="right"
      />
      <Errors errors={errors['user.email']}/>

      <Input
        placeholder="Password"
        type="password"
        value={user.password}
        onChange={({ target: { value } }) => dispatch(setUserPassword(value))}
        fluid
        required
        width="12"
        style={{ borderRadius: '0', height: '50px' }}
      />
      <Errors errors={errors['user.password']}/>

      <Input
        placeholder="Repeat password"
        type="password"
        value={user.password_confirmation}
        onChange={({ target: { value } }) => dispatch(setUserPasswordConfirm(value))}
        fluid
        required
        width="12"
        style={{ borderRadius: '0', height: '50px' }}
      />
      <Errors errors={errors['user.password_confirmation']}/>

      <button className="button-primary">Register</button>
    </Form>
  )
}

const RegistrationInvitation = () => {
  const { hash } = useParams()

  const { token } = useSelector((state) => state.login)

  if (token !== '') {
    return <Redirect to="/"/>
  }

  return (
    <main>
      <div className="authentication-page__wrapper">
        <div className="background__wrapper background__wrapper_register-page">
          <div className="background-pattern background-pattern_register-page"/>
          <div className="text__container">
            <p className="title">There is strength in independence but there is power in community.</p>
            <p>Dr. Madeline Ofina</p>
            <ul>
              <li>Created and designed by industry’s experts</li>
              <li>Connecting the industry</li>
              <li>Secure payment</li>
              <li>24/7 One stop shop</li>
            </ul>
          </div>
        </div>
        <div className="form__wrapper">
          <div className="logo__container">
            <div className="logo-img"/>
          </div>
          <div className="form__container">
            <h2>Create your account</h2>
            <RegisterForm hash={hash}/>
          </div>
        </div>
      </div>
    </main>
  )
}

export default RegistrationInvitation
