import {
  changeConfig, deleteUserFailure, deleteUserRequest, deleteUserSuccess,
  fetchCompanyFailure,
  fetchCompanyRequest,
  fetchCompanySuccess,
  rollbackConfigs,
  saveCompanyConfigsFailure,
  saveCompanyConfigsRequest,
  saveCompanyConfigsSuccess,
  toggleEditMode
} from './actions'

const initialState = {
  loading: true,
  company: {},
  usersCount: 0,
  editMode: false,
  put: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchCompanyRequest.toString():
    case saveCompanyConfigsRequest.toString():
      return { ...state, loading: true }
    case fetchCompanyFailure.toString():
    case saveCompanyConfigsFailure.toString():
      return { ...state, loading: false }
    case fetchCompanySuccess.toString():
    case saveCompanyConfigsSuccess.toString(): {
      const configs = (payload.configs || []).reduce((carry, config) => ({
        ...carry,
        [config.name]: config.value
      }), {})

      return {
        ...state,
        loading: false,
        company: { ...payload, configs },
        put: { ...configs }
      }
    }

    case toggleEditMode.toString():
      return { ...state, editMode: payload }
    case changeConfig.toString():
      return { ...state, put: { ...state.put, [payload.type]: payload.value } }
    case rollbackConfigs.toString():
      return { ...state, put: { ...state.company.configs } }

    case deleteUserRequest.toString(): {
      return { ...state, loading: true }
    }
    case deleteUserFailure.toString(): {
      return { ...state, loading: false }
    }
    case deleteUserSuccess.toString(): {
      return { ...state, loading: false }
    }
  }

  return state
}
