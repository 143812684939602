import React from 'react'
import { Header, Segment } from 'semantic-ui-react'
import { QuotationReference, QuotationStatus } from '@components/quotation'

const QuotationHeader = ({ loading, quotation: { reference = '', status = {} } = {}, showReference = true }) => {

  const segmentStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }

  return (
    <Segment style={segmentStyle}>
      <Header as="h1" style={{ margin: '0' }}>Quotation</Header>
      {showReference ? <QuotationReference loading={loading} reference={reference}/> : false}
      <QuotationStatus loading={loading} size="big" status={status}/>
    </Segment>
  )
}

export default QuotationHeader