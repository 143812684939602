import {
  cleanState,
  createOrderFailure,
  createOrderRequest,
  createOrderSuccess,
  fetchQuotationFailure,
  fetchQuotationRequest,
  fetchQuotationSuccess,
} from './actions'

import { companiesWithProducts, formatProducts } from '../../../../util'

const initialState = {
  loading: false,
  quotation: {
    status: {},
  },
  companies: [],
  orders: [],
}

export default (state = initialState, { type, payload }) => {

  switch (type) {
    case cleanState.toString():
      return { ...initialState }
    case fetchQuotationRequest.toString():
      return { ...state, loading: true }
    case fetchQuotationSuccess.toString():
      return {
        ...state,
        loading: false,
        quotation: payload.quotation,
        companies: companiesWithProducts({
          companies: payload.companies,
          products: formatProducts(payload.quotation)
        }).map(v => ({ ...v, ui: {}, order: (v.orders.length ? v.orders[0] : {}) }))
      }
    case fetchQuotationFailure.toString():
      return { ...state, loading: false }

    //

    case createOrderRequest.toString(): {
      const { company } = payload

      return {
        ...state,
        companies: [...state.companies].map((v) => {
          if (v.public_id === company.public_id) {
            return { ...v, ui: { loading: true, disabled: false } }
          }
          return { ...v, ui: { loading: false, disabled: true } }
        })
      }
    }
    case createOrderSuccess().toString():
    case createOrderFailure().toString(): {
      return {
        ...state,
        companies: [...state.companies].map((v) => ({ ...v, ui: { loading: false, disabled: false } }))
      }
    }
  }

  return state
}
