import React from 'react'
import { Route } from 'react-router'

import ShopRoutes from './shop'
import SupplierRoutes from './supplier'

export default function ({ dashboard }) {

  switch (dashboard) {
    case 'shop':
      return <ShopRoutes/>
    case 'supplier':
      return <SupplierRoutes/>
  }

  return (
    <Route exact path="/">
      Dashboard: ${dashboard}
    </Route>
  )
}
