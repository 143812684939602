import React, { Fragment, useEffect } from 'react'
import { Button, Dropdown, Form, Header, Segment } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  clearState,
  companySetSelectedSupplier,
  companyUpdateSetChecked,
  companyUpdateSetEnabled,
  companyUpdateSetSupplier,
  fetchCompany,
  fetchSuppliers,
  saveCompanySupplier,
  toggleChecked,
  toggleEnabled
} from './actions'
import { BackButton } from '@components'
import { CompanyConfigs, CompanyHeader, CompanyUsers } from '@components/company'

const SelectSupplier = () => {
  const dispatch = useDispatch()
  const { company, suppliers, selectedSupplier } = useSelector((state) => state.shop.company)
  const { supplier } = company

  const setSupplier = () => {
    const selectedSupplierObject = suppliers.find(supplier => supplier.id === selectedSupplier)
    dispatch(companyUpdateSetSupplier(selectedSupplierObject))
    dispatch(saveCompanySupplier({ id: company.public_id, supplierId: selectedSupplier }))
  }

  useEffect(() => {
    dispatch(fetchSuppliers())
  }, [])

  if (supplier) {
    return <div style={{ fontSize: '1.28571429em' }}>{supplier.name}</div>
  }

  return (
    <Form style={{ display: 'flex', alignItems: 'center' }} onSubmit={setSupplier}>
      <Dropdown
        search={true}
        scrolling
        style={{ marginRight: '5px' }}
        placeholder="Select supplier"
        options={suppliers.map(v => ({ key: v.id, value: v.id, text: v.name }))}
        value={selectedSupplier}
        onChange={(e, { value }) => dispatch(companySetSelectedSupplier(value))}
      />
      <Button type="submit" primary disabled={!selectedSupplier}>Save</Button>
    </Form>
  )
}

const Company = () => {
  const { id: companyId } = useParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCompany(companyId))

    return () => {dispatch(clearState())}
  }, [companyId])

  const { loading, company, company: { users, configs, checked, enabled } } = useSelector((state) => state.shop.company)

  const checkedChange = () => {
    dispatch(companyUpdateSetChecked(!checked))
    dispatch(
      toggleChecked({
        companyPublicId: company.public_id,
      })
    )
  }

  const enabledChange = () => {
    dispatch(companyUpdateSetEnabled(!enabled))
    dispatch(
      toggleEnabled({
        companyPublicId: company.public_id,
      })
    )
  }

  return (
    <Fragment>
      <BackButton/>

      <Segment.Group raised style={{ marginTop: '3em', minWidth: '60em' }}>
        <CompanyHeader
          loading={loading}
          editable={true}
          company={company}
          checkedChange={checkedChange}
          enabledChange={enabledChange}
        />
        <Segment>
          <div style={{ marginBottom: '20px' }}>
            <Header as="h3">Supplier:</Header>
            <SelectSupplier/>
          </div>
        </Segment>
        <Segment>
          <CompanyConfigs
            company={company}
            configs={configs}
            editable={false}
          />
        </Segment>
      </Segment.Group>

      <CompanyUsers loading={loading} company={company} users={users}/>
    </Fragment>
  )
}

export default Company
