import React from 'react'
import { useSelector } from 'react-redux'

import { QuotationList } from '@components/quotation'
import { clearState, fetchQuotations } from './actions'

const Quotations = () => {

  const { loading, list: quotations, count, limit } = useSelector((state) => state.supplier.quotations)
  const { statuses, reference } = useSelector((state) => state.quotationFilter)

  return (
    <QuotationList
      loading={loading}
      quotations={quotations}
      statuses={statuses}
      reference={reference}
      count={count}
      limit={limit}
      fetch={fetchQuotations}
      clear={clearState}
    />
  )
}

export default Quotations
