import React, { Fragment, useEffect } from 'react'
import { Button, Dropdown, Input, Segment } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { changeCompany, changeReference, fetchOrderStatus, fetchCompanies, toggleChecked } from './actions'

const OrderFilterCompany = ({ loading = true, onChange = () => {} }) => {
  const dispatch = useDispatch()

  const { company, companies = [] } = useSelector((state) => state.orderFilter)

  useEffect(() => {
    dispatch(fetchCompanies())
  }, [true])

  const change = (value) => {
    onChange()
    dispatch(changeCompany(value))
  }

  if (companies.length === 0) {
    return false
  }

  const options = [
    { key: '_', value: '', text: 'Clear selection' },
    ...companies.map(v => ({
      key: v.public_id,
      value: v.public_id,
      text: v.name
    }))
  ]

  return (
    <Fragment>
      <Dropdown
        className="icon order-status"
        placeholder="Select company"
        loading={loading}
        button
        icon="industry"
        labeled
        floating
        search
        options={options}
        value={company}
        onChange={(event, { value }) => {change(value)}}
      />
    </Fragment>
  )
}

const OrderFilterReference = ({ loading = true, onChange = () => {} }) => {
  const dispatch = useDispatch()

  const { reference } = useSelector((state) => state.orderFilter)

  const change = (value) => {
    onChange()
    dispatch(changeReference(value))
  }

  return (
    <Input
      placeholder="Reference..."
      className="order-status"
      loading={loading}
      value={reference}
      onChange={({ target: { value } }) => change(value)}
      icon="search"
      iconPosition="left"
    />
  )
}

const OrderFilterStatus = ({ loading = true, filterCompanies = false, onChange = () => {} }) => {

  const dispatch = useDispatch()

  const { statuses: list } = useSelector((state) => state.orderFilter)

  useEffect(() => {
    dispatch(fetchOrderStatus())
  }, [true])

  const toggle = (status) => {
    onChange()
    dispatch(toggleChecked(status.id))
  }

  return list.filter(v => v.id !== 1 ? true : filterCompanies).map(status => {
    const style = status.checked ? {
      backgroundColor: status.color,
      color: 'white',
      border: 'none',
      cursor: 'pointer'
    } : { border: 'none', cursor: 'pointer' }

    return (
      <div className="order-status"
           key={status.id}
           style={{ display: 'flex', alignItems: 'center' }}>

        <Button
          size="small"
          loading={loading}
          disabled={loading}
          style={style}
          onClick={() => toggle(status)}
        >
          {status.name}
        </Button>
      </div>
    )
  })
}

const OrderFilter = ({ loading = true, filterCompanies = false, onChange = () => {} }) => (
  <Fragment>
    <Segment.Group raised>
      <Segment style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
        <OrderFilterReference loading={loading} onChange={onChange}/>
        <OrderFilterStatus loading={loading} onChange={onChange} filterCompanies={filterCompanies}/>
      </Segment>
      {filterCompanies ? (
        <Segment raised>
          <OrderFilterCompany loading={loading} onChange={onChange}/>
        </Segment>
      ) : false}
    </Segment.Group>

  </Fragment>
)

export default OrderFilter
