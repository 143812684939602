import { Icon, Pagination as SemanticPagination } from 'semantic-ui-react'
import React from 'react'

const Pagination = ({ count, limit, page, onPageChange }) => {
  const totalPages = Math.ceil(count / limit)

  if (totalPages < 2) {
    return false
  }

  return (
    <SemanticPagination
      activePage={page}
      totalPages={totalPages}
      onPageChange={(event, data) => onPageChange(data)}
      ellipsisItem={{ content: <Icon name="ellipsis horizontal"/>, icon: true }}
      firstItem={{ content: <Icon name="angle double left"/>, icon: true }}
      lastItem={{ content: <Icon name="angle double right"/>, icon: true }}
      prevItem={{ content: <Icon name="angle left"/>, icon: true }}
      nextItem={{ content: <Icon name="angle right"/>, icon: true }}
    />
  )
}

export default Pagination
