import {
  fetchStatusFailure,
  fetchStatusRequest,
  fetchStatusSuccess,
  toggleChecked,
  changeCompany,
  changeReference,
  fetchCompaniesFailure,
  fetchCompaniesRequest,
  fetchCompaniesSuccess
} from './actions'

const listPreloaded = [
  { 'color': '#FF8C00', 'code': 'RCVD', 'name': 'Awaiting Quote', 'id': 1 },
  { 'color': '#32CD32', 'code': 'SENT', 'name': 'In negotiation', 'id': 2 },
  { 'color': '#4169E1', 'code': 'ORDR', 'name': 'Outstanding Payment', 'id': 6 },
  { 'color': '#108510', 'code': 'CLSD', 'name': 'Approved Closed', 'id': 7 },
  { 'color': '#DC143C', 'code': 'INCP', 'name': 'Rejected Incomplete', 'id': 8 },
  { 'color': '#FF0000', 'code': 'CCLD', 'name': 'Cancelled', 'id': 10 },
  { 'color': '#FF0000', 'code': 'CORD', 'name': 'Customer Order Request', 'id': 11 }
]

const initialState = {
  loading: false,
  statuses: [...listPreloaded],
  companies: [],
  reference: '',
  company: ''
}

export default (state = initialState, { type, payload }) => {

  switch (type) {
    case fetchStatusRequest.toString():
      return { ...state, loading: true }
    case fetchStatusSuccess.toString():
      return { ...state, loading: false, statuses: payload.map(v => ({ ...v, checked: false })) }
    case fetchStatusFailure.toString():
      return { ...state, loading: false }
    case fetchCompaniesRequest.toString():
      return { ...state, loading: true }
    case fetchCompaniesSuccess.toString():
      return { ...state, loading: false, companies: payload }
    case fetchCompaniesFailure.toString():
      return { ...state, loading: false }
    case toggleChecked.toString() :
      return {
        ...state,
        statuses: [...state.statuses.map(v => {
          if (v.id === payload) {
            return { ...v, checked: !v.checked }
          }

          return v
        })]
      }
    case changeReference.toString() :
      return { ...state, reference: payload }
    case changeCompany.toString() :
      return { ...state, company: payload }
  }

  return state
}
