import {
  cleanState,
  fetchQuotationFailure,
  fetchQuotationRequest,
  fetchQuotationSuccess,
  quotationAnswerClear,
  quotationAnswerFailure,
  quotationAnswerSetDeliveryTime,
  quotationAnswerSetPrice,
  quotationAnswerSuccess,
} from './actions'

import { formatProducts, productsWithAnswers } from '@util'

const initialState = {
  loading: false,
  quotation: {
    status: {},
  },
  products: [],
  answer: {
    price: 0,
    deliveryTime: '',
  },
  modalErrors: []
}

export default (state = initialState, { type, payload }) => {

  switch (type) {
    case cleanState.toString():
      return { ...initialState }
    case fetchQuotationRequest.toString():
      return { ...state, loading: true }
    case fetchQuotationSuccess.toString():
      return {
        ...state,
        loading: false,
        quotation: payload.quotation,
        products: productsWithAnswers({
          quotation: payload.quotation,
          products: formatProducts(payload.quotation),
        })
      }
    case fetchQuotationFailure.toString():
      return { ...state, loading: false }
    case quotationAnswerSetPrice.toString():
      return { ...state, answer: { ...state.answer, price: payload } }
    case quotationAnswerSetDeliveryTime.toString():
      return { ...state, answer: { ...state.answer, deliveryTime: payload } }
    case quotationAnswerClear.toString():
      return { ...state, answer: { price: 0, deliveryTime: '' } }
    case quotationAnswerSuccess.toString():
      return { ...state, loading: false, modalErrors: [] }
    case quotationAnswerFailure.toString():
      return { ...state, loading: false, modalErrors: Object.values(payload) }
  }

  return state
}
